import { permissions } from "./AllPermission";

export default {
  storeadmin: ["*"],
  superadmin: ["*"],
  admin: ["*"],
  generalaccountant: [
    //! Dashboard
    permissions.dashboard,
    permissions.dashboardCashers,
    permissions.dashboardSafeboxes,
    permissions.dashboardTabs,
    //? product section
    //! product
    permissions.productList,
    permissions.productView,

    //! process Invoice
    permissions.processInvoiceList,

    //? customer section
    //! customer
    permissions.customerList,

    //! customer payment
    permissions.customerPaymentList,
    permissions.customerPaymentCreate,
    permissions.customerPaymentView,

    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,
    permissions.sellInvoiceCreate,
    permissions.sellInvoiceView,

    //? Representative section
    //! Representative
    permissions.representativeList,
    permissions.representativeCreate,
    permissions.representativeUpdate,
    permissions.representativeDelete,
    permissions.representativeView,
    //? Representative Customer section
    //! Representative Customer
    permissions.representativeCustomerList,
    permissions.representativeCustomerCreate,
    permissions.representativeCustomerUpdate,
    permissions.representativeCustomerDelete,
    permissions.representativeCustomerView,
    //? Cashier section
    //! sells
    permissions.cashierSellList,
    //! Return
    permissions.cashierReturnList,
    //! WithDraws
    permissions.cashierWithdrawList,
    permissions.cashierWithdrawView,
    permissions.cashierWithdrawCreate,
    permissions.cashierWithdrawDelete,
  ],
  accountant: [
    //! Dashboard
    permissions.dashboard,
    permissions.dashboardCashers,
    permissions.dashboardTabs,
    permissions.dashboardSafeboxes,

    //? product section
    //! product
    permissions.productList,
    permissions.productCreate,
    permissions.productUpdate,
    permissions.productDelete,
    permissions.productView,
    //! product Category
    permissions.productCategoryList,
    //! product Brand
    permissions.productBrandList,
    //! damage Invoice
    permissions.damageInvoiceList,
    //! damage Type
    permissions.damageTypeList,

    //! Store Transfer
    permissions.storeTransferList,
    permissions.storeTransferCreate,
    permissions.storeTransferView,

    // //! store inventory Invoice
    // permissions.storeInventoryInvoiceList,
    // permissions.storeInventoryInvoiceCreate,
    // permissions.storeInventoryInvoiceUpdate,
    // permissions.storeInventoryInvoiceDelete,
    // permissions.storeInventoryInvoiceView,

    //? customer section
    //! customer
    permissions.customerList,
    permissions.customerView,
    //! customer payment
    permissions.customerPaymentList,
    permissions.customerPaymentCreate,
    permissions.customerPaymentUpdate,
    permissions.customerPaymentDelete,
    permissions.customerPaymentView,

    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,
    permissions.sellInvoiceCreate,
    permissions.sellInvoiceUpdate,
    permissions.sellInvoiceView,
    //! Sell return Invoice
    permissions.returnSellInvoiceCreate,
    permissions.returnSellInvoiceList,
    permissions.returnSellInvoiceDelete,
    permissions.returnSellInvoiceView,

    //? supplier section
    //! Supplier
    permissions.supplierList,
    permissions.supplierCreate,
    permissions.supplierUpdate,
    permissions.supplierDelete,
    permissions.supplierView,
    //! Supplier payment
    permissions.supplierPaymentList,
    permissions.supplierPaymentCreate,
    permissions.supplierPaymentUpdate,
    permissions.supplierPaymentDelete,
    permissions.supplierPaymentView,

    //? purchase invoice section
    //! purchase Invoice
    permissions.purchaseInvoiceList,
    permissions.purchaseInvoiceCreate,
    permissions.purchaseInvoiceUpdate,
    permissions.purchaseInvoiceDelete,
    permissions.purchaseInvoiceView,

    //! purchase return Invoice
    permissions.returnPurchaseInvoiceList,
    permissions.returnPurchaseInvoiceCreate,
    permissions.returnPurchaseInvoiceDelete,
    permissions.returnPurchaseInvoiceView,

    //? Cashier section
    //! sells
    permissions.cashierSellList,
    //! Return
    permissions.cashierReturnList,
    //! WithDraws
    permissions.cashierWithdrawList,
    permissions.cashierWithdrawView,
    permissions.cashierWithdrawCreate,
    permissions.cashierWithdrawDelete,

    //? HR section
    //! HR
    permissions.HrList,

    //? Accountant section
    //! Bank Accounts
    permissions.bankAccountList,
    permissions.bankAccountUpdate,
    permissions.bankAccountView,

    //! Bank account transfer History
    permissions.storeTransactionHistoriesList,

    //! Bank account transfer
    permissions.bankAccountTransferList,
    permissions.bankAccountTransferCreate,
    permissions.bankAccountTransferView,

    //! User Store Transaction Histories
    permissions.userStoreTransactionHistoriesList,

    //! User Store Bank Account Transfer
    permissions.userStoreBankAccountTransferList,
    permissions.userStoreBankAccountTransferCreate,
    permissions.userStoreBankAccountTransferView,

    //! Balance Sheet
    permissions.balanceSheet,

    //! deposit
    permissions.depositList,
    //! withdraw
    permissions.withdrawList,
    //!
    permissions.expenseList,

    //! Currency exchange
    permissions.currencyExchangeList,
    permissions.currencyExchangeCreate,
    permissions.currencyExchangeView,
    // ? Setting
    // ! setting
    permissions.setting,
    // ? Reports module
    // ! General Report
    permissions.generalReport,
    // ! HR Reports
    permissions.hrReport,
    // ! Item Report Reports
    permissions.itemReport,
    // ! Supplier Report
    permissions.supplierReport,
  ],
  cashertrue: [
    //! Dashboard
    permissions.dashboard,
    permissions.dashboardCashers,

    //? product section
    //! product
    permissions.productList,
    permissions.productView,
    //! damage Invoice
    permissions.damageInvoiceList,
    //! damage Type
    permissions.damageTypeList,

    //! store inventory Invoice
    permissions.storeInventoryInvoiceList,
    permissions.storeInventoryInvoiceCreate,
    permissions.storeInventoryInvoiceView,

    //? customer section
    //! customer
    permissions.customerList,
    permissions.customerCreate,
    permissions.customerUpdate,
    permissions.customerView,

    //! customer payment
    permissions.customerPaymentList,
    permissions.customerPaymentCreate,
    permissions.customerPaymentView,

    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,
    permissions.sellInvoiceUpdate,
    permissions.sellInvoiceView,

    //! Sell return Invoice
    permissions.returnSellInvoiceList,
    permissions.returnSellInvoiceCreate,
    permissions.returnSellInvoiceView,

    //? supplier section
    //! Supplier
    permissions.supplierList,
    permissions.supplierView,

    //! Supplier payment
    permissions.supplierPaymentList,
    permissions.supplierPaymentView,

    //? purchase invoice section
    //! purchase Invoice
    permissions.purchaseInvoiceList,
    permissions.purchaseInvoiceView,

    //! purchase return Invoice
    permissions.returnPurchaseInvoiceList,
    permissions.returnPurchaseInvoiceView,

    //? Cashier section
    //! sells
    permissions.cashierSellList,
    //! Return
    permissions.cashierReturnList,

    //? HR section
    //! HR
    permissions.HrList,

    //? Accountant section

    //! Bank Accounts
    permissions.bankAccountList,
    permissions.bankAccountUpdate,
    permissions.bankAccountView,

    //! Bank account transfer History
    permissions.storeTransactionHistoriesList,

    //! Bank account transfer
    permissions.bankAccountTransferList,
    permissions.bankAccountTransferView,

    //! User Store Transaction Histories
    permissions.userStoreTransactionHistoriesList,

    //! User Store Bank Account Transfer
    permissions.userStoreBankAccountTransferList,
    permissions.userStoreBankAccountTransferCreate,
    permissions.userStoreBankAccountTransferView,

    //! Currency exchange
    permissions.currencyExchangeList,
    permissions.currencyExchangeCreate,
    permissions.currencyExchangeView,

    // ? Setting
    // ! setting
    permissions.setting,

    // ? Reports module
    // ! General Report
    permissions.generalReport,
    // ! Supplier Report
    permissions.supplierReport,
  ],
  casher: [
    //! Dashboard
    permissions.dashboard,
    permissions.dashboardCashers,
    permissions.dashboardTabs,
    permissions.dashboardSafeboxes,

    //? product section
    //! product
    permissions.productList,
    permissions.productView,

    //! product Category
    permissions.productCategoryList,

    //! product Brand
    permissions.productBrandList,

    //! Store Transfer
    permissions.storeTransferList,
    permissions.storeTransferView,

    //! store inventory Invoice
    permissions.storeInventoryInvoiceList,
    permissions.storeInventoryInvoiceView,

    //? customer section
    //! customer
    permissions.customerList,
    permissions.customerCreate,
    permissions.customerUpdate,
    permissions.customerView,

    //! customer payment
    permissions.customerPaymentList,
    permissions.customerPaymentCreate,
    permissions.customerPaymentView,

    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,
    permissions.sellInvoiceCreate,
    permissions.sellInvoiceUpdate,
    permissions.sellInvoiceView,

    //! Sell return Invoice
    permissions.returnSellInvoiceList,
    permissions.returnSellInvoiceView,

    //? supplier section
    //! Supplier
    permissions.supplierList,
    permissions.supplierView,

    //! Supplier payment
    permissions.supplierPaymentList,
    permissions.supplierPaymentView,

    //? purchase invoice section
    //! purchase Invoice
    permissions.purchaseInvoiceList,
    permissions.purchaseInvoiceView,

    //! purchase return Invoice
    permissions.returnPurchaseInvoiceList,
    permissions.returnPurchaseInvoiceView,

    //? Accountant section
    //! Balance Sheet
    permissions.balanceSheet,

    //! User Store Transaction Histories
    permissions.userStoreTransactionHistoriesList,

    //! Currency exchange
    permissions.currencyExchangeList,
    permissions.currencyExchangeView,

    // ? Reports module
    // ! General Report
    permissions.generalReport,
    // ! HR Reports
    permissions.hrReport,
    // ! Item Report Reports
    permissions.itemReport,
    // ! Supplier Report
    permissions.supplierReport,
  ],
  stockkeeper: [
    //? product section
    //! product
    permissions.productList,
    permissions.productView,

    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,
    permissions.sellInvoiceCreate,
  ],
  dataentry: [
    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,

    //! Sell return Invoice
    permissions.returnSellInvoiceList,

    //? purchase invoice section
    //! purchase Invoice
    permissions.purchaseInvoiceList,

    //! purchase return Invoice
    permissions.returnPurchaseInvoiceList,
  ],
  debtcollector: [
    //? customer section
    //! customer
    permissions.customerList,
    //! customer payment
    permissions.customerPaymentList,
    permissions.customerPaymentView,

    //? sell invoice section
    //! Sell Invoice
    permissions.sellInvoiceList,

    // ? Reports module
    // ! General Report
    permissions.generalReport,
  ],
};
