import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  FunctionField,
  useRefresh,
  downloadCSV,
  Show,
  SimpleShowLayout,
  DateField,
  ShowButton,
  EditButton,
  ArrayField,
  NumberField,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { url } from "../../request";
import swal from "sweetalert";
import moment from "moment";
import { ListActions } from "../templates/ListActions";
import { path } from "../../request";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import jsonExport from "jsonexport/dist";
import { NoPrint } from "react-easy-print";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { permissions } from "../Permissions/AllPermission";
import { Product_Edit } from "./Product_Edit";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  return (
    <NoPrint>
      <React.Fragment>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              swal({
                text: `${translate("resources.root.newExpireDate")}`,
                content: {
                  element: "input",
                  attributes: {
                    type: "date",
                  },
                },
                button: {
                  text: `${translate("resources.root.update")}`,
                  closeModal: false,
                },
              }).then((expireDate) => {
                if (expireDate) {
                  HTTP.post(
                    `${url}/Products/UpdateExpireDate?newExpireDate=${expireDate}`,
                    props.selectedIds
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        swal("Oh noes!", "The AJAX request failed!", "error");
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                }
              });
            }}
          >
            <AssignmentTurnedInRoundedIcon /> &nbsp;{" "}
            {translate("resources.root.updateExpireDate")}
          </Button>
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              swal({
                text: `${translate("resources.root.updateSellPrice")}`,
                content: {
                  element: "input",
                  attributes: {
                    type: "number",
                  },
                },
                button: {
                  text: `${translate("resources.root.update")}`,
                  closeModal: false,
                },
              }).then((ratio) => {
                if (ratio) {
                  HTTP.post(
                    `${url}/Products/UpdateSellPriceByProfitRate?ratio=${
                      ratio / 100
                    }`,
                    props.selectedIds
                  )
                    .then(() => {
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        swal("Oh noes!", "The AJAX request failed!", "error");
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                }
              });
            }}
          >
            <AssignmentTurnedInRoundedIcon /> &nbsp;{" "}
            {translate("resources.root.updateSellPrice")}
          </Button>
          <BulkAction {...props} />
        </div>
      </React.Fragment>
    </NoPrint>
  );
};

export const ProductsList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const [products, setProducts] = React.useState("default");

  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let {
        id,
        name,
        barcode,
        mcSellPrice,
        scSellPrice,
        sellByMain,
        qteInStock,
        mcPurchasePrice,
        scPurchasePrice,
        purchaseByMain,
        mcSmallMeasureSellPrice,
        scSmallMeasureSellPrice,
        scSellMinPrice,
        mcSellMinPrice,
        scSellMaxPrice,
        mcSellMaxPrice,
        expireDate,
        ratio,
      } = data;
      let sellMain = sellByMain
        ? translate("resources.root.usd")
        : translate("resources.root.iqd");
      let purchaseMain = purchaseByMain
        ? translate("resources.root.usd")
        : translate("resources.root.iqd");
      let expire =
        expireDate !== null ? moment(expireDate).format("YYYY-MM-DD") : "-";
      return {
        id,
        name,
        barcode,
        mcSellPrice,
        scSellPrice,
        sellMain,
        qteInStock,
        mcPurchasePrice,
        scPurchasePrice,
        purchaseMain,
        mcSmallMeasureSellPrice,
        scSmallMeasureSellPrice,
        scSellMinPrice,
        mcSellMinPrice,
        scSellMaxPrice,
        mcSellMaxPrice,
        expire,
      };
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "id",
          "name",
          "barcode",
          "mcSellPrice",
          "scSellPrice",
          "sellMain",
          "qteInStock",
          "mcPurchasePrice",
          "scPurchasePrice",
          "purchaseMain",
          "mcSmallMeasureSellPrice",
          "scSmallMeasureSellPrice",
          "scSellMinPrice",
          "mcSellMinPrice",
          "scSellMaxPrice",
          "mcSellMaxPrice",
          "expire",
        ],
        rename: [
          translate("resources.root.id"),
          process.env.REACT_APP_APP_NAME === "Mediply"
            ? translate("resources.root.scientificName")
            : translate("resources.root.name"),
          translate("resources.root.barcode"),
          translate("resources.root.mcSellPrice"),
          translate("resources.root.scSellPrice"),
          translate("resources.root.sellCurrency"),
          translate("resources.root.quantity"),
          translate("resources.root.mcPurchasePrice"),
          translate("resources.root.scPurchasePrice"),
          translate("resources.root.purchaseCurrency"),
          translate("resources.root.mcSmallMeasureSellPrice"),
          translate("resources.root.scSmallMeasureSellPrice"),
          translate("scSellMinPrice"),
          translate("mcSellMinPrice"),
          translate("scSellMaxPrice"),
          translate("mcSellMaxPrice"),
          translate("resources.root.expireDate"),
          translate("resources.root.updateSellPrice"),
        ],
      },
      (err, csv) => {
        downloadCSV(
          "\ufeff" + csv,
          "products." + moment(new Date()).format("YYMMDDHHmmssa")
        );
      }
    );
  };

  const translate = useTranslate();

  return (
    <List
      debounce={1500}
      exporter={exporter}
      filters={<SearchFilter brand={true} category={true} loses={true} />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate={"product.create"}
        />
      }
      pagination={<MyCustomPagination />}
    >
      <Datagrid
        bulkActionButtons={
          <BulkActionButtons
            setProducts={setProducts}
            hasDelete="product.delete"
          />
        }
        rowStyle={(record) => ({
          backgroundColor:
            record.mcPurchasePrice > record.mcSellPrice ||
            record.mcSmallMeasureSellPrice <
              record.mcPurchasePrice / record.oneContains
              ? "tomato"
              : "transparent",
        })}
      >
        <FunctionField
          sortBy={"id"}
          label="resources.root.id"
          render={(record) => (
            <a
              href={`#/Reports/ProductStatement/${record.id}`}
              target={`_blank`}
            >{`${record.id}`}</a>
          )}
        />
        <FunctionField
          sortBy="attachment"
          label={`resources.root.image`}
          render={(record) => (
            <img
              style={{
                width: 75,
                height: 75,
                objectFit: "scale-down",
                cursor: "pointer",
              }}
              src={
                record.attachment !== null
                  ? `${path}${record.attachment}`
                  : `https://via.placeholder.com/75`
              }
              alt={record.name}
              onClick={() =>
                window.open(
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`,
                  "_blank"
                )
              }
            />
          )}
        />
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <TextField source="name" label="resources.root.scientificName" />
        ) : (
          <TextField source="name" label="resources.root.name" />
        )}
        {process.env.REACT_APP_APP_NAME === "Mediply" && (
          <TextField source="preferName" label="resources.root.BusinessName" />
        )}
        <TextField source="barcode" label="resources.root.barcode" />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcSellPrice"}
            label="resources.root.selling"
            render={(record) =>
              record.sellByMain
                ? `$${record?.mcSellPrice?.toLocaleString()}`
                : `${record?.scSellPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {localStorage.getItem("showPurchasePriceInList") === "true" &&
          currency === "default" &&
          process.env.REACT_APP_APP_NAME !== "Mediply" && (
            <FunctionField
              sortBy={"mcPurchasePrice"}
              label="resources.root.purchase"
              render={(record) =>
                record.purchaseByMain
                  ? `$${record?.mcPurchasePrice?.toLocaleString()}`
                  : `${record?.scPurchasePrice?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />
          )}

        {currency !== "default" && (
          <FunctionField
            sortBy={"mcSellPrice"}
            label="resources.root.sellingPrice"
            render={(record) =>
              currency === "dollar"
                ? `$${record?.mcSellPrice}`
                : `${record?.scSellPrice} ${translate("resources.root.iqd")}`
            }
          />
        )}
        <TextField source="qteInStock" label="resources.root.quantity" />
        <TextField
          source="smallMeasureQte"
          label="resources.root.smallMeasureQty"
        />
        {/* Showing productsion date for all other clients except mediply which we show production date */}
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <FunctionField
            sortBy={"productionDate"}
            label="resources.root.productionDate"
            render={(record) =>
              record.productionDate
                ? moment(record.productionDate).format("YYYY-MM-DD")
                : "-"
            }
          />
        ) : (
          <FunctionField
            sortBy={"expireDate"}
            label="resources.root.expireDate"
            render={(record) =>
              record.expireDate
                ? moment(record.expireDate).format("YYYY-MM-DD")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}

        {hasPermission([permissions?.productView]) && <ShowButton />}
        {hasPermission([permissions?.productUpdate]) && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const ProductsShow = (props) => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="barcode" label="resources.root.barcode" />
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <TextField source="name" label="resources.root.scientificName" />
        ) : (
          <TextField source="name" label="resources.root.name" />
        )}
        {process.env.REACT_APP_APP_NAME === "Mediply" ? (
          <TextField source="preferName" label="resources.root.BusinessName" />
        ) : (
          <TextField source="preferName" label="resources.root.preferedName" />
        )}
        <TextField
          source="shortDescription"
          label="resources.root.shortDescription"
        />
        <TextField
          source="longDescription"
          label="resources.root.longDescription"
        />
        <TextField
          source="productMeasure"
          label="resources.root.productMeasure"
        />
        <TextField source="weightInKg" label="resources.root.weightInKg" />
        <TextField source="oneContains" label="resources.root.oneContains" />
        <TextField source="oneMeasure" label="resources.root.oneMeasure" />
        <TextField
          source="productCategory.name"
          label="resources.root.category"
        />
        <TextField source="brand.name" label="resources.root.brand" />
        <NumberField
          source="initialQte"
          label="resources.root.initialQuantity"
        />
        {/*  Showing list of the stores */}
        <ArrayField source="storeProducts" label="resources.root.stores">
          <Datagrid>
            <TextField source="store.title" label="resources.root.store" />
            {process.env.REACT_APP_APP_NAME !== "Mediply" && (
              <FunctionField
                label="resources.root.purchasePrice"
                render={(record) =>
                  record.purchaseByMain
                    ? record.mcPurchasePrice?.toLocaleString()
                    : record.scPurchasePrice?.toLocaleString()
                }
              />
            )}
            <FunctionField
              label="resources.root.sellPrice"
              render={(record) =>
                record.sellByMain
                  ? record.mcSellPrice?.toLocaleString()
                  : record.scSellPrice?.toLocaleString()
              }
            />
            <FunctionField
              label="resources.root.smallMeasureSellPrice"
              render={(record) =>
                record.sellByMain
                  ? record.mcSmallMeasureSellPrice?.toLocaleString()
                  : record.scSmallMeasureSellPrice?.toLocaleString()
              }
            />
            <NumberField
              source="qteInStock"
              label="resources.root.qteInStock"
            />
            <NumberField
              source="initialQte"
              label="resources.root.initialQuantity"
            />
            <NumberField source="alertQte" label="resources.root.alertQty" />
            <DateField
              source="expireDate"
              label="resources.root.expireDate"
              locales={"en-GB"}
            />
          </Datagrid>
        </ArrayField>
        {/* Showin list of serials */}
        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
          <ArrayField source="productSerials" label="resources.root.serials">
            <Datagrid>
              <TextField source="serialNumber" label="resources.root.serial" />
              {process.env.REACT_APP_APP_NAME !== "Mediply" && (
                <FunctionField
                  label="resources.root.purchasePrice"
                  render={(record) =>
                    record.purchaseByMain
                      ? record.mcPurchasePrice?.toLocaleString()
                      : record.scPurchasePrice?.toLocaleString()
                  }
                />
              )}
              <FunctionField
                label="resources.root.sellPrice"
                render={(record) =>
                  record.sellByMain
                    ? record.mcSellPrice?.toLocaleString()
                    : record.scSellPrice?.toLocaleString()
                }
              />
              <FunctionField
                label="resources.root.smallMeasureSellPrice"
                render={(record) =>
                  record.sellByMain
                    ? record.mcSmallMeasureSellPrice?.toLocaleString()
                    : record.scSmallMeasureSellPrice?.toLocaleString()
                }
              />
              <NumberField
                source="qteInStock"
                label="resources.root.qteInStock"
              />
              <NumberField
                source="initialQte"
                label="resources.root.initialQuantity"
              />
              <NumberField source="alertQte" label="resources.root.alertQty" />
              <DateField
                source="productionDate"
                label="resources.root.productionDate"
                locales={"en-GB"}
              />
              <DateField
                source="expirationDate"
                label="resources.root.expireDate"
                locales={"en-GB"}
              />
            </Datagrid>
          </ArrayField>
        )}
        <TextField source="barcode1" label="resources.root.barcode1" />
        <TextField source="barcode2" label="resources.root.barcode2" />
        <TextField source="barcode3" label="resources.root.barcode3" />
        <TextField source="barcode4" label="resources.root.barcode4" />
        <TextField source="barcode5" label="resources.root.barcode5" />
        <TextField source="barcode6" label="resources.root.barcode6" />
        <TextField source="barcode7" label="resources.root.barcode7" />
        <TextField source="barcode8" label="resources.root.barcode8" />
        <TextField source="barcode9" label="resources.root.barcode9" />
      </SimpleShowLayout>
    </Show>
  );
};

export const ProductsEdit = () => {
  return <Product_Edit />;
};

const useStyles = makeStyles((theme) => ({
  input: {
    width: "30%",
  },
  formContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  perColumnInput: {
    flex: 1,
    marginLeft: 5,
  },
  productInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
  },
  storeProductInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    marginBottom: 10,
    flexWrap: "wrap",
    "&:after": {
      content: "''",
      flex: 1,
    },
  },
  sampleStoreProductInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    marginBottom: 10,
    backgroundColor: "#f2f2f2",
    flexWrap: "wrap",
    paddingTop: 10,
    "&:after": {
      content: "''",
      flex: 1,
    },
  },
  productInputsColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 5,
  },
  ProductsInput: {
    marginRight: 10,
    width: "15%",
  },
  root: {
    marginTop: 72,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  productCols: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  productNumberInput: {
    width: "15% !important",
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  productTextInput: {
    width: "20% !important",
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  productLongTextInput: {
    width: "25% !important",
    marginLeft: 10,
  },
  tableHeader: {
    textAlign: "center",
  },
  tableContainer: {
    width: "100%",
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
  },
  secondary: {
    // backgroundColor: 'rgb(220, 0, 78)',
    color: "rgb(220, 0, 78)",
  },
  switchLabel: {
    fontFamily: "monospace",
  },
}));
