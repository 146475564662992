import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  FunctionField,
  EditButton,
  Edit,
  DateField,
  NumberField,
  required,
  DateInput,
  ReferenceInput,
  NumberInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  useNotify,
  Show,
  SimpleShowLayout,
  useTranslate,
  useResourceDefinition,
  ImageField,
  ImageInput,
  ShowButton,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import Button from "@mui/material/Button";
import { url, handleRequestResponse } from "../../request";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/VoucherReceipt/Page";
import { HTTP } from "../../axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { path } from "../../request";
import { permissions } from "../Permissions/AllPermission";

export const ExpensesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();
  return (
    <List
      filters={<SearchFilter date={true} />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="expense.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="expense.list" />}
      >
        <TextField source="id" label="resources.root.id" />
        <TextField source="title" label="resources.root.title" />
        {localStorage.getItem("showInvoiceNumber") === "true" && (
          <TextField
            source="invoiceNumber"
            label="resources.root.invoiceNumber"
          />
        )}
        <TextField
          source="expenseTypeLabel"
          label="resources.root.expenseType"
        />

        {currency === "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label={translate("resources.root.price")}
            render={(record) =>
              record.byMain
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcPrice"}
            label={translate("resources.root.price")}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcPrice?.toLocaleString()}`
                : `${record.scPrice?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`
            }
          />
        )}
        <NumberField
          source="qte"
          label={translate("resources.root.quantity")}
        />
        {currency === "default" && (
          <FunctionField
            label={translate("resources.root.totalPrice")}
            sortBy={"mcTotalPrice"}
            render={(record) =>
              record.byMain
                ? `$${
                    record.mcTotalPrice && record.mcTotalPrice?.toLocaleString()
                  }`
                : `${
                    record.scTotalPrice && record.scTotalPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.totalPrice")}
            sortBy={"mcTotalPrice"}
            render={(record) =>
              currency === "dollar"
                ? `$${
                    record.mcTotalPrice && record.mcTotalPrice?.toLocaleString()
                  }`
                : `${
                    record.scTotalPrice && record.scTotalPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}

        <DateField
          source="date"
          label={translate("resources.root.date")}
          showTime
          locales={"en-GB"}
        />

        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}

        <ShowButton />
        {hasPermission([permissions?.expenseUpdate]) && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const ExpensesCreate = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(false);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [total, setTotal] = React.useState(1);
  const [expenseTypes, setExpenseTypes] = React.useState([]);

  React.useEffect(() => {
    HTTP.get(`${url}/ExpenseTypes?_end=10000`).then((res) => {
      setExpenseTypes(res.data);
    });
  }, []);

  React.useEffect(() => {
    setSeparatedNumber(0);
  }, [currency]);

  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        date: data.date + " " + moment().format("hh:mm:ss a"),
      })}
      redirect="list"
    >
      <SimpleForm>
        <TextInput
          source="title"
          label={translate("resources.root.title")}
          validate={[required()]}
        />
        <TextInput
          source="invoiceNumber"
          label={translate("resources.root.invoiceNumber")}
        />
        <TextInput
          source="description"
          options={{ multiLine: true }}
          label={translate("resources.root.description")}
        />
        <DateInput
          source="date"
          defaultValue={moment(new Date()).format("YYYY-MM-DD")}
          label={translate("resources.root.date")}
        />
        <AutocompleteInput
          label={translate("resources.root.expenseType")}
          source="expenseTypeId"
          choices={expenseTypes}
          optionText="label"
          optionValue="id"
          validate={[required()]}
        />
        <TextInput
          source="note"
          label={translate("resources.root.note")}
          options={{ multiLine: true }}
        />
        <NumberInput
          source="qte"
          defaultValue={1}
          label={translate("resources.root.quantity")}
        />
        <BooleanInput
          source="byMain"
          defaultValue={false}
          label={
            currency
              ? translate("resources.root.dollar")
              : translate("resources.root.dinar")
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setCurrency(formData.byMain);
            let totalPrice = formData.qte * parseFloat(separatedNumber);
            setTotal(isNaN(totalPrice) ? 0 : totalPrice);

            return formData.byMain ? (
              <NumberInput
                source="mcPrice"
                onChange={(e) => setSeparatedNumber(e.target.value)}
                helperText={
                  separatedNumber > 0
                    ? `$${parseFloat(separatedNumber)?.toLocaleString()}`
                    : ""
                }
                defaultValue={0}
                label={translate("resources.root.price")}
              />
            ) : (
              <NumberInput
                source="scPrice"
                onChange={(e) => setSeparatedNumber(e.target.value)}
                helperText={
                  separatedNumber > 0
                    ? `${parseFloat(
                        separatedNumber
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`
                    : ""
                }
                defaultValue={0}
                label={translate("resources.root.price")}
              />
            );
          }}
        </FormDataConsumer>
        <p>
          {translate("resources.root.total")}:{" "}
          {currency
            ? `$${total?.toLocaleString()}`
            : `${total?.toLocaleString()} ${translate("resources.root.iqd")}`}
        </p>
        <ImageInput
          source="attachment"
          label="resources.root.attachment"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export const ExpensesEdit = (props) => {
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState(true);
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.status === 410) {
      notify(translate("resources.root.pleaseCheckYourSettings"), {
        type: "warning",
        undoable: true,
      });
      window.open("#/Settings/create", "_self");
    } else {
      notify("ra.notification.http_error", { type: "warning", undoable: true });
    }
  };

  React.useEffect(() => {
    setSeparatedNumber(0);
  }, [currency]);

  return (
    <Edit {...props} onFailure={onFailure} redirect="list">
      <SimpleForm>
        <TextInput
          source="title"
          label={translate("resources.root.title")}
          validate={[required()]}
        />
        <TextInput
          source="description"
          options={{ multiLine: true }}
          label={translate("resources.root.description")}
        />
        <DateInput
          source="date"
          defaultValue={new Date()}
          label={translate("resources.root.date")}
        />
        <ReferenceInput
          label={translate("resources.root.expenseType")}
          source="expenseTypeId"
          reference="ExpenseTypes"
          validate={[required()]}
          perPage={500}
        >
          <SelectInput optionText="label" />
        </ReferenceInput>
        <TextInput
          source="note"
          label={translate("resources.root.note")}
          options={{ multiLine: true }}
        />
        <NumberInput
          source="qte"
          label={translate("resources.root.quantity")}
        />
        <BooleanInput
          source="byMain"
          defaultValue={true}
          label={
            currency
              ? translate("resources.root.dollar")
              : translate("resources.root.dinar")
          }
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setCurrency(formData.byMain);
            let totalPrice = formData.qte * parseFloat(separatedNumber);
            setTotal(isNaN(totalPrice) ? 0 : totalPrice);

            return formData.byMain ? (
              <NumberInput
                source="mcPrice"
                onChange={(e) => setSeparatedNumber(e.target.value)}
                helperText={
                  separatedNumber > 0
                    ? `$${parseFloat(separatedNumber)?.toLocaleString()}`
                    : ""
                }
                label={translate("resources.root.price")}
              />
            ) : (
              <NumberInput
                source="scPrice"
                onChange={(e) => setSeparatedNumber(e.target.value)}
                helperText={
                  separatedNumber > 0
                    ? `${parseFloat(
                        separatedNumber
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`
                    : ""
                }
                label={translate("resources.root.price")}
              />
            );
          }}
        </FormDataConsumer>
        <FunctionField
          label={translate("resources.root.total")}
          render={(record) =>
            currency
              ? `$${total?.toLocaleString()}`
              : `${total?.toLocaleString()} ${translate("resources.root.iqd")}`
          }
        />
        <ImageInput
          source="attachment"
          label="resources.root.attachment"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FunctionField
          label="resources.root.attachment"
          render={(record) => (
            <img
              style={{
                width: 200,
                height: 200,
                objectFit: "scale-down",
                cursor: "pointer",
              }}
              src={`${path}${record.attachment}`}
              alt="Attachment"
              onClick={() =>
                window.open(`${path}${record.attachment}`, "_blank")
              }
            />
          )}
        />
      </SimpleForm>
    </Edit>
  );
};

export const ExpensesShow = () => {
  const [mainColor, SetMainColor] = React.useState(
    localStorage.getItem("mainColorExpense") !== null
      ? localStorage.getItem("mainColorExpense")
      : "#14a44d"
  );
  localStorage.setItem("mainColorExpense", mainColor);
  const { id } = useParams();
  const props = useResourceDefinition();
  const [expense, setExpense] = React.useState({});
  const translate = useTranslate();
  React.useEffect(() => {
    HTTP.get(`${url}/Expenses/${id}`).then((response) => {
      setExpense(response.data);
    });
  }, [props]);
  return (
    <div>
      <Page
        data={{
          invoiceNo: expense.invoiceNumber && expense.invoiceNumber,
          serialNo: expense.id && expense.id,
          dollar: expense.byMain && expense.byMain,
          isDeposit: false,
          date: expense.date && expense.date,
          payedTo: expense.title && expense.title,
          amount: expense?.byMain
            ? expense.mcTotalPrice && expense.mcTotalPrice?.toLocaleString()
            : expense.scTotalPrice && expense.scTotalPrice?.toLocaleString(),
          type: "Expense",
          note:
            expense.qte &&
            (
              expense.qte * (expense.byMain ? expense.mcPrice : expense.scPrice)
            )?.toLocaleString() +
              (expense.description ? " - " + expense.description : ""),
        }}
      />
      <Show>
        <SimpleShowLayout>
          <TextField source="title" label={translate("resources.root.title")} />
          <TextField
            source="description"
            label={translate("resources.root.description")}
          />
          <TextField
            source="invoiceNumber"
            label={translate("resources.root.invoiceNumber")}
          />
          <DateField
            source="date"
            label={translate("resources.root.date")}
            locales={"en-GB"}
          />
          <TextField
            source="expenseType.label"
            label={translate("resources.root.expenseType")}
          />
          <TextField source="note" label={translate("resources.root.note")} />
          <TextField
            source="qte"
            label={translate("resources.root.quantity")}
          />
          <FunctionField
            label={translate("resources.root.price")}
            render={(record) =>
              record.byMain
                ? record.mcPrice?.toLocaleString()
                : record.scPrice?.toLocaleString()
            }
          />
          <FunctionField
            label={translate("resources.root.totalPrice")}
            render={(record) =>
              record.byMain
                ? record.mcTotalPrice?.toLocaleString()
                : record.scTotalPrice?.toLocaleString()
            }
          />
          <FunctionField
            label="resources.root.attachment"
            render={(record) => (
              <img
                style={{
                  width: 200,
                  height: 200,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={`${path}${record.attachment}`}
                alt="Attachment"
                onClick={() =>
                  window.open(`${path}${record.attachment}`, "_blank")
                }
              />
            )}
          />
          <div className="d-flex align-items-center w-100 gap-1">
            <Button
              class={`btn btn-primary w-75`}
              onClick={() => {
                let appLayout = document.getElementsByClassName("theRoot");
                let sidebar = document.getElementsByClassName("MuiDrawer-root");
                let appMenu = document.getElementsByClassName("MuiPaper-root");
                let appBar = document.getElementsByClassName("theRoot");
                let showPage = document.getElementsByClassName("show-page");
                let printInvoice = document.getElementById("hidePage");
                printInvoice.classList.remove("d-none");
                appLayout[0].style.marginTop = "0";
                sidebar[0].style.display = "none";
                appMenu[0].style.display = "none";
                appBar[0].style.marginTop = "0px";
                showPage[0].style.display = "none";
                window.print();
                printInvoice.classList.add("d-none");
                appLayout[0].style.marginTop = "10px";
                sidebar[0].style.display = "block";
                appMenu[0].style.display = "block";
                appBar[0].style.marginTop = "40px";
                showPage[0].style.display = "block";
              }}
            >
              {translate("resources.root.print")}
            </Button>
            {(process.env.REACT_APP_APP_NAME === "Sherko" ||
              process.env.REACT_APP_APP_NAME === "SherkoNew") && (
              <div className="d-flex justify-content-center align-items-center w-25">
                <p className="m-0 p-0 ">
                  {translate("resources.root.selectColorVoucher")}:&nbsp;
                </p>
                <div className="cp_wrapper">
                  <input
                    className="input-voucher"
                    type="color"
                    value={mainColor}
                    onChange={(e) => SetMainColor(e.target.value)}
                  />
                </div>
              </div>
            )}
          </div>
        </SimpleShowLayout>
      </Show>
    </div>
  );
};
