import * as React from "react";
import { useRedirect, useTranslate } from "react-admin";
import { url } from "../../request";
import { Card, Col, Row } from "react-bootstrap";
import { HTTP } from "../../axios";
import ItemSupplierReport from "../reports/ItemSupplierReport";
// import { FaCashRegister } from "react-icons/fa6";
// import { BiSolidBank } from "react-icons/bi";
import LoadingScreen from "../CustomComponents/LoadingScreen";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BalanceSheet = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const role = localStorage.getItem("role") ?? "NoUser";

  const _ = require("lodash");
  const translate = useTranslate();
  const redirect = useRedirect();
  const [balanceSheet, setBalanceSheet] = React.useState([]);

  const [bankAccount, setBankAccount] = React.useState([]);
  const [sumOfBankAccount, setSumOfBankAccount] = React.useState({
    mc: 0,
    sc: 0,
  });

  const [cashiers, setCashiers] = React.useState([]);
  const [sumOfCashiers, setSumOfCashiers] = React.useState({
    mc: 0,
    sc: 0,
  });

  React.useEffect(() => {
    setIsLoading(true);
    HTTP.get(`${url}/Dashboard/BalanceSheet`)
      .then((response) => {
        setBalanceSheet(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });

    if (
      role.toLowerCase() === "accountant" &&
      (process.env.REACT_APP_APP_NAME == "Bahez" ||
        process.env.REACT_APP_APP_NAME == "Safeen")
    ) {
      HTTP.get(`${url}/bankaccounts?GetAll=${true}`)
        .then((response) => {
          setBankAccount(response.data);
          setSumOfBankAccount({
            mc: _.sumBy(response.data, (bank) => {
              return bank.mcCurrentBalance;
            }),
            sc: _.sumBy(response.data, (bank) => {
              return bank.scCurrentBalance;
            }),
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            redirect("/Login");
          }
          console.log(error.response.status);
        });
    } else {
      HTTP.get(`${url}/bankaccounts`)
        .then((response) => {
          setBankAccount(response.data);
          setSumOfBankAccount({
            mc: _.sumBy(response.data, (bank) => {
              return bank.mcCurrentBalance;
            }),
            sc: _.sumBy(response.data, (bank) => {
              return bank.scCurrentBalance;
            }),
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            redirect("/Login");
          }
          console.log(error.response.status);
        });
    }
    HTTP.get(`${url}/UserStoreBankAccounts`)
      .then((response) => {
        setCashiers(response.data);
        setSumOfCashiers({
          mc: _.sumBy(response.data, (cashier) => {
            return cashier.mcCurrentCash;
          }),
          sc: _.sumBy(response.data, (cashier) => {
            return cashier.scCurrentCash;
          }),
        });
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
  }, [0]);

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="m-5">
      <h1 className={`text-center`}>
        {translate("resources.root.balanceSheet")}
      </h1>
      <div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-between align-items-center balanc-sheet-bg">
          <div className="p-4 card-main-container">
            <div
              className={`d-flex flex-column border-main rounded-lg text-center`}
            >
              <div className="card-main-header p-2 ">
                {translate("resources.root.receivable")}{" "}
                <a href="#/Customers" className={`text-light `}>
                  <u>{translate("resources.root.fromCustomers")}</u>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-5 py-3 ">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${balanceSheet?.mcReceivable?.toLocaleString()}`}</p>
                )}
                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${balanceSheet?.scReceivable?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </div>
          </div>

          <div className="p-4 card-main-container">
            <div
              className={`d-flex flex-column border-main rounded-lg text-center`}
            >
              <div className="card-main-header p-2 ">
                {translate("resources.root.payable")}{" "}
                <a href="#/Suppliers" className={`text-light `}>
                  <u>{translate("resources.root.toSuppliers")}</u>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-5 py-3">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${balanceSheet?.mcPayable?.toLocaleString()}`}</p>
                )}
                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${balanceSheet?.scPayable?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </div>
          </div>

          <div className="p-4 card-main-container">
            <div
              className={`d-flex flex-column border-main rounded-lg text-center `}
            >
              <div className="card-main-header p-2 ">
                <a href="#/Products" className={`text-light `}>
                  <u>{translate("resources.root.products")}</u>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-5 py-3">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${balanceSheet?.mcProducts?.toLocaleString()}`}</p>
                )}

                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${balanceSheet?.scProducts?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </div>
          </div>

          <div className="p-4 card-main-container">
            <div
              className={`d-flex flex-column border-main rounded-lg text-center `}
            >
              <div className="card-main-header p-2 ">
                <a href="#/Products" className={`text-light `}>
                  <u>{translate("resources.root.totalProductQty")}</u>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <p className="d-flex  align-items-center gap-5 py-3 flex-wrap">
                  <span>
                    {balanceSheet?.productsTotalQte?.toLocaleString()}
                  </span>
                  <span>
                    {balanceSheet?.productsTotalSmallQte?.toLocaleString()}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="p-4 card-main-container">
            <div
              className={`d-flex flex-column border-main rounded-lg text-center`}
            >
              <div className="card-main-header p-2 ">
                <a href="#/Expenses" className={`text-light `}>
                  <u>{translate("resources.root.paidExpenses")}</u>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-5 py-3 ">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${balanceSheet?.mcPaidExpenses?.toLocaleString()}`}</p>
                )}

                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${balanceSheet?.scPaidExpenses?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </div>
          </div>

          <div className="p-4 card-main-container">
            <div
              className={`d-flex flex-column border-main rounded-lg text-center`}
            >
              <div className="card-main-header p-2 ">
                <a href="#/EmployeeSalaryPays" className={`text-light `}>
                  <u>{translate("resources.root.paidSalaries")}</u>
                </a>
              </div>
              <div className="d-flex justify-content-center align-items-center gap-5 py-3 ">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${balanceSheet?.mcPaidSalaries?.toLocaleString()}`}</p>
                )}

                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${balanceSheet?.scPaidSalaries?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {bankAccount?.length > 0 && (
          <Accordion className="w-100 my-2">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content1"
              id="panel1-header1"
              className="h5 text-cneter"
            >
              <p>
                {translate("resources.root.totalBankAccount")}: &nbsp;&nbsp;
              </p>
              <div className="d-flex justify-content-center gap-5 align-items-center h5">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${sumOfBankAccount?.mc?.toLocaleString()}`}</p>
                )}
                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${sumOfBankAccount?.sc?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </AccordionSummary>
            <div className="row justify-content-center align-items-center  balanc-sheet-bg">
              {bankAccount?.map((item) => {
                return (
                  <AccordionDetails
                    className="p-4 card-main-container col-12 col-md-6 col-lg-4"
                    key={item.id}
                  >
                    <div
                      className={`d-flex flex-column border-main rounded-lg text-center `}
                    >
                      <div className="card-main-header p-2 d-flex justify-content-center align-items-center gap-2">
                        <p>{item.name}</p>
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-5 py-3 ">
                        {process.env.REACT_APP_SHOW_MC === "true" && (
                          <p>{`$${item?.mcCurrentBalance?.toLocaleString()}`}</p>
                        )}
                        {process.env.REACT_APP_SHOW_SC === "true" && (
                          <p>{`${item?.scCurrentBalance?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}</p>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                );
              })}
            </div>
          </Accordion>
        )}
        {cashiers?.length > 0 && (
          <Accordion className="w-100">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="h5 text-cneter"
            >
              <p>{translate("resources.root.totalCashiers")}: &nbsp;&nbsp;</p>
              <div className="d-flex justify-content-center gap-5 align-items-center h5">
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p>{`$${sumOfCashiers?.mc?.toLocaleString()}`}</p>
                )}
                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p>{`${sumOfCashiers?.sc?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`}</p>
                )}
              </div>
            </AccordionSummary>
            <div className="row justify-content-center align-items-center  balanc-sheet-bg">
              {cashiers?.map((item) => {
                return (
                  <AccordionDetails
                    className="p-4 card-main-container col-12 col-md-6 col-lg-4"
                    key={item.id}
                  >
                    <div
                      className={`d-flex flex-column border-main rounded-lg text-center `}
                    >
                      <div className="card-main-header p-2 d-flex justify-content-center align-items-center gap-2">
                        <p>
                          {item.firstName} {item.middleName}{" "}
                        </p>
                        {/* <FaCashRegister /> */}
                      </div>
                      <div className="d-flex justify-content-center align-items-center gap-5 py-3 ">
                        {process.env.REACT_APP_SHOW_MC === "true" && (
                          <p>{`$${item?.mcCurrentCash?.toLocaleString()}`}</p>
                        )}
                        {process.env.REACT_APP_SHOW_SC === "true" && (
                          <p>{`${item?.scCurrentCash?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`}</p>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                );
              })}
            </div>
          </Accordion>
        )}

        <div className="p-4 card-main-container  balanc-sheet-bg">
          <div
            className={`d-flex flex-column border-red rounded-lg text-center`}
          >
            <div className="card-main-header-red p-2 ">
              {translate("resources.root.finalPrice")}
            </div>
            <div className="d-flex justify-content-center align-items-center  gap-5 py-3">
              {process.env.REACT_APP_SHOW_MC === "true" && (
                <p>{`$${(
                  sumOfBankAccount?.mc +
                  sumOfCashiers?.mc +
                  balanceSheet?.mcReceivable +
                  balanceSheet?.mcProducts -
                  balanceSheet?.mcPayable
                )?.toLocaleString()}`}</p>
              )}
              {process.env.REACT_APP_SHOW_SC === "true" && (
                <p>{`${(
                  sumOfBankAccount?.sc +
                  sumOfCashiers?.sc +
                  balanceSheet?.scReceivable +
                  balanceSheet?.scProducts -
                  balanceSheet?.scPayable
                )?.toLocaleString()} ${translate("resources.root.iqd")}`}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSheet;
