import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Show,
  ShowButton,
  TabbedShowLayout,
  Tab,
  Create,
  BooleanField,
  FormTab,
  TabbedForm,
  useTranslate,
  useNotify,
  DateField,
  useRefresh,
  useResourceDefinition,
  DateInput,
  ReferenceField,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { handleRequestResponse, url } from "../../request";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";

import swal from "sweetalert";
import Swal from "sweetalert2";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import { permissions } from "../Permissions/AllPermission";
import CreatePurchasePreInvoice from "../invoices/CreatePurchasePreInvoice";
import _, { filter } from "lodash";
import {
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const notify = useNotify();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.selectedIds.length === 1 && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                title: "Multiple inputs",
                html:
                  '<label className="text-dark">Pay Price:</label> <input value="0" id="swal-input1" type="number" className="swal2-input">' +
                  ' <label className="text-dark">Forgive:</label> <input  value="0" id="swal-input2" type="number" className="swal2-input">',
                showCancelButton: true,
                focusConfirm: false,
                inputValue: 0,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.submit"),
                cancelButtonText: translate("ra.action.cancel"),
                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/Pay?invoiceId=${
                      props.selectedIds[0]
                    }&payPrice=${
                      document.getElementById("swal-input1").value
                    }&forgivePrice=${
                      document.getElementById("swal-input2").value
                    }`
                  )
                    .then(() => {
                      notify(translate("resources.root.PaidSuccessfully"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.pay")}
          </Button>
        )}
        {((props.selectedIds.length === 1 &&
          process.env.REACT_APP_APP_NAME === "Bahez") ||
          process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                icon: "warning",
                title: translate("resources.root.swalTitle"),
                text: translate("resources.root.payTaxText"),
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("ra.action.cancel"),

                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/PayTaxPayment/${
                      props.selectedIds[0]
                    }?toggle=${true}`
                  )
                    .then(() => {
                      notify(translate("resources.root.paidSuccessfully"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.payTax")}
          </Button>
        )}

        <BulkAction
          {...props}
          bulkDeleteButton={true}
          hasPrintOrders={"PurchaseInvoice"}
          hasDelete="purchaseinvoice.delete"
        />
      </div>
    </React.Fragment>
  );
};

export const PurchasesPreList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print force>
          <List
            debounce={1500}
            filters={<SearchFilter driver={true} date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions
                setCurrency={setCurrency}
                currency={currency}
                hasCreate="purchaseinvoice.create"
              />
            }
          >
            <Datagrid
              bulkActionButtons={
                // <BulkAction
                //   {...props}
                //   bulkDeleteButton={true}
                //   hasPrintOrders={"PurchaseInvoice"}
                // />
                <BulkActionButtons />
              }
            >
              <TextField source="id" label={translate("resources.root.id")} />
              <FunctionField
                sortBy={"supplierName"}
                label="resources.root.supplierName"
                render={(record) => (
                  <a
                    href={`#/Reports/SupplierStatement?id=${record.supplier.id}`}
                    target={`_blank`}
                  >
                    {record.supplier.supplierName}
                  </a>
                )}
              />
              {localStorage.getItem("showInvoiceNumber") === "true" && (
                <TextField
                  source="invoiceNumber"
                  label="resources.root.invoiceNumber"
                />
              )}
              <FunctionField
                label={translate("resources.root.quantity")}
                render={(record) =>
                  (record.totalQte - record.totalGiftQte)?.toLocaleString()
                }
              />
              <TextField
                source="totalGiftQte"
                label="resources.root.giftQuantity"
              />
              <TextField
                source="totalQte"
                label="resources.root.totalQuantity"
              />
              <FunctionField
                render={(record) =>
                  moment(record.date).format("DD/MM/YYYY hh:mm:ss A")
                }
                sortBy={"date"}
                label="resources.root.date"
              />
              <BooleanField
                source="isCash"
                label={translate("resources.root.cash")}
              />
              {localStorage.getItem("showNote") === "true" && (
                <TextField source="note" label="resources.root.note" />
              )}
              {localStorage.getItem("showDriver") === "true" && (
                <TextField source="driver" label="resources.root.driver" />
              )}
              {localStorage.getItem("showLastUser") === "true" && (
                <TextField source="lastUser" label="resources.root.lastUser" />
              )}
              {localStorage.getItem("showCreatedAt") === "true" && (
                <FunctionField
                  sortBy={"createdAt"}
                  label="resources.root.createdAt"
                  render={(record) =>
                    record.createdAt
                      ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                      : "-"
                  }
                />
              )}{" "}
              {hasPermission([permissions?.purchaseInvoiceView]) && (
                <ShowButton />
              )}
              {hasPermission([permissions?.purchaseInvoiceUpdate]) && (
                <EditButton />
              )}
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};

export const PurchasesPreCreate = (props) => (
  <Create {...props} component="div">
    <CreatePurchasePreInvoice />
  </Create>
);

export const PurchasesPreShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const classes = useStyles();
  const [brands, setBrands] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [orders, setOrders] = React.useState([]);
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  const [showInfo, setShowInfo] = React.useState([]);
  const [filteredBrands, setFilteredBrands] = React.useState(null);
  const [isFilteredBrands, setIsFilteredBrands] = React.useState(false);
  const [filteredCategories, setFilteredCategories] = React.useState(null);
  const [isFilteredCategories, setIsFilteredCategories] = React.useState(false);
  const [filteredTitle, setFilteredTitle] = React.useState(null);
  const notify = useNotify();

  React.useEffect(() => {
    // Brands
    HTTP.get("/brands?_start=0&_end=1000")
      .then((response) => {
        setBrands(response.data);
      })
      .catch((err) => handleRequestResponse(notify, err));

    HTTP.get("/productCategories?_start=0&_end=1000")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => handleRequestResponse(notify, err));

    // Invoice Data
    HTTP.get(`${url}/PurchasePreInvoices/${id}`)
      .then((res) => {
        setInvoice(res.data);
      })
      .catch((err) => handleRequestResponse(notify, err));
  }, [props]);

  React.useEffect(() => {
    if (invoice) {
      setShowInfo([
        { title: "invoiceNumber", data: invoice?.invoiceNumber },
        {
          title: "date",
          data: `${moment(invoice?.date).format("YYYY / MM / DD")} -- ${moment(
            invoice?.date
          ).format("hh:mm:ss")}`,
        },
        { title: "branch", data: invoice?.supplier?.supplierName },
        { title: "quantity", data: invoice?.totalQte },
        { title: "note", data: invoice?.note, class: "col-12" },
      ]);
      setOrders(invoice?.purchasePreOrders);
    }
  }, [invoice]);

  const translate = useTranslate();
  const handleSperateItemsByBrands = () => {
    if (isFilteredCategories && !isFilteredBrands)
      setIsFilteredCategories(false);
    const groupedByBrand = _.groupBy(orders, (item) => item.product.brandId);
    setFilteredBrands(groupedByBrand);

    setIsFilteredBrands(!isFilteredBrands);
  };
  const handleSperateItemsByCategory = () => {
    if (isFilteredBrands && !isFilteredCategories) setIsFilteredBrands(false);

    const groupedByCateogry = _.groupBy(
      orders,
      (item) => item.product.productCategoryId
    );
    setFilteredCategories(groupedByCateogry);
    setIsFilteredCategories(!isFilteredCategories);
  };

  const printTriger = () => {
    let sidebar = document.getElementsByClassName("MuiDrawer-root");
    sidebar[0].style.display = "none";
    window.print();
    sidebar[0].style.display = "block";
  };
  React.useEffect(() => {
    if (filteredOrders?.length > 0) {
      printTriger();
    }
  }, [filteredOrders, filteredTitle]);
  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={`${translate("resources.root.purchasePreInvoices")} ${
                filteredTitle !== null ? `- ${filteredTitle}` : ""
              }`}
              order={filteredOrders || orders}
              human={invoice.supplier}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show className="">
            <div className="row row-cols-4 px-4 py-2 ">
              {showInfo?.map((item, index) => {
                return (
                  item.data && (
                    <div key={index} className={`p-2 px-3 ${item?.class}`}>
                      <div className="card-show text-center rounded">
                        <p className="py-1 h6">
                          {translate(`resources.root.${item?.title}`)}
                        </p>
                        <div className="blured py-3">
                          <p className="text-nowrap h6 d-flex justify-content-around">
                            {item?.data}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
            {isFilteredBrands ? (
              <div className="row row-cols-1 px-4 py-2">
                {Object?.entries(filteredBrands)?.map(([brandId, items]) => {
                  const currentBrand = brands.find(
                    (brand) => brand.id === parseInt(brandId)
                  );

                  return (
                    <Accordion id={brandId}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <p className="text-uppercase bold m-1">
                          {currentBrand.name}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails className="p-0 m-0">
                        <Button
                          variant="contained"
                          className={classes.botton + " mx-3 mb-3"}
                          onClick={() => {
                            if (items[0]?.id === filteredOrders[0]?.id) {
                              printTriger();
                            }
                            setFilteredTitle(currentBrand?.name);
                            setFilteredOrders(items);
                          }}
                        >
                          {translate("resources.root.print")}
                        </Button>
                        <TableContainer
                          component={Paper}
                          className={classes.tableContainer + " p-0 m-0"}
                        >
                          <Table
                            stickyHeader
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {translate("resources.root.barcode")}
                                </TableCell>
                                <TableCell align="right">
                                  {translate("resources.root.name")}
                                </TableCell>
                                {process.env.REACT_APP_PRODUCT_SERIAL ===
                                  "true" && (
                                  <TableCell align="right">
                                    {translate("resources.root.serial")}
                                  </TableCell>
                                )}
                                <TableCell align="right">
                                  {translate("resources.root.quantity")}
                                </TableCell>

                                <TableCell align="right">
                                  {translate("resources.root.productMeasure")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className={classes.scrollable}>
                              {items &&
                                items.map((order) => (
                                  <TableRow key={order.product.barcode}>
                                    <TableCell component="th" scope="row">
                                      {order.product.barcode}
                                    </TableCell>
                                    <TableCell align="right">
                                      <a
                                        href={`#/Products/${order.product.id}`}
                                      >
                                        {order.product.name}
                                      </a>
                                    </TableCell>
                                    {process.env.REACT_APP_PRODUCT_SERIAL ===
                                      "true" && (
                                      <TableCell align="right">
                                        {order.productSerial?.serialNumber}
                                      </TableCell>
                                    )}
                                    <TableCell align="right">
                                      {order.qte +
                                        " + " +
                                        order.smallMeasureQte}
                                    </TableCell>

                                    <TableCell align="right">{`${order.productMeasure} => ${order.oneContains} ${order.oneMeasure}`}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            ) : isFilteredCategories ? (
              <div className="row row-cols-1 px-4 py-2">
                {Object?.entries(filteredCategories)?.map(
                  ([categoryId, items]) => {
                    const currentCategory = categories.find(
                      (category) => category.id === parseInt(categoryId)
                    );

                    return (
                      <Accordion id={categoryId}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <p className="text-uppercase bold m-1">
                            {currentCategory?.name}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 m-0">
                          <Button
                            variant="contained"
                            className={classes.botton + " mx-3 mb-3"}
                            onClick={() => {
                              if (items[0]?.id === filteredOrders[0]?.id) {
                                printTriger();
                              }
                              setFilteredTitle(currentCategory?.name);
                              setFilteredOrders(items);
                            }}
                          >
                            {translate("resources.root.print")}
                          </Button>
                          <TableContainer
                            component={Paper}
                            className={classes.tableContainer + " p-0 m-0"}
                          >
                            <Table
                              stickyHeader
                              className={classes.table}
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    {translate("resources.root.barcode")}
                                  </TableCell>
                                  <TableCell align="right">
                                    {translate("resources.root.name")}
                                  </TableCell>
                                  {process.env.REACT_APP_PRODUCT_SERIAL ===
                                    "true" && (
                                    <TableCell align="right">
                                      {translate("resources.root.serial")}
                                    </TableCell>
                                  )}
                                  <TableCell align="right">
                                    {translate("resources.root.quantity")}
                                  </TableCell>

                                  <TableCell align="right">
                                    {translate("resources.root.productMeasure")}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody className={classes.scrollable}>
                                {items &&
                                  items.map((order) => (
                                    <TableRow key={order.product.barcode}>
                                      <TableCell component="th" scope="row">
                                        {order.product.barcode}
                                      </TableCell>
                                      <TableCell align="right">
                                        <a
                                          href={`#/Products/${order.product.id}`}
                                        >
                                          {order.product.name}
                                        </a>
                                      </TableCell>
                                      {process.env.REACT_APP_PRODUCT_SERIAL ===
                                        "true" && (
                                        <TableCell align="right">
                                          {order.productSerial?.serialNumber}
                                        </TableCell>
                                      )}
                                      <TableCell align="right">
                                        {order.qte +
                                          " + " +
                                          order.smallMeasureQte}
                                      </TableCell>

                                      <TableCell align="right">{`${order.productMeasure} => ${order.oneContains} ${order.oneMeasure}`}</TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                )}
              </div>
            ) : (
              <TableContainer
                component={Paper}
                className={classes.tableContainer}
              >
                <Table
                  stickyHeader
                  className={classes.table}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {translate("resources.root.barcode")}
                      </TableCell>
                      <TableCell align="right">
                        {translate("resources.root.name")}
                      </TableCell>
                      {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                        <TableCell align="right">
                          {translate("resources.root.serial")}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        {translate("resources.root.quantity")}
                      </TableCell>

                      <TableCell align="right">
                        {translate("resources.root.productMeasure")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.scrollable}>
                    {orders &&
                      orders.map((order) => (
                        <TableRow key={order.product.barcode}>
                          <TableCell component="th" scope="row">
                            {order.product.barcode}
                          </TableCell>
                          <TableCell align="right">
                            <a href={`#/Products/${order.product.id}`}>
                              {order.product.name}
                            </a>
                          </TableCell>
                          {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                            <TableCell align="right">
                              {order.productSerial?.serialNumber}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {order.qte + " + " + order.smallMeasureQte}
                          </TableCell>

                          <TableCell align="right">{`${order.productMeasure} => ${order.oneContains} ${order.oneMeasure}`}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Show>
          <div className="d-flex flex-wrap justify-content-start align-items-center gap-2 my-2">
            <Button
              variant="contained"
              className={classes.botton}
              onClick={() => {
                setFilteredOrders(orders);
                setFilteredTitle(null);
                if (orders[0]?.product?.id === filteredOrders[0]?.id) {
                  printTriger();
                }
              }}
            >
              {translate("resources.root.print")}
            </Button>
            <FormControlLabel
              className="m-0 text-nowrap text-center my-2"
              control={
                <Switch
                  checked={isFilteredBrands}
                  onClick={handleSperateItemsByBrands}
                  name="isBrands"
                />
              }
              label={translate("resources.root.filterByBrands")}
            />
            <FormControlLabel
              className="m-0 text-nowrap text-center my-2"
              control={
                <Switch
                  checked={isFilteredCategories}
                  onClick={handleSperateItemsByCategory}
                  name="isCategories"
                />
              }
              label={translate("resources.root.filterByCategories")}
            />
          </div>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const PurchasesPreEdit = () => {
  return (
    <div component="div">
      <CreatePurchasePreInvoice />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },

  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {},
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
