import * as React from "react";
import {
  TextInput,
  NumberInput,
  Edit,
  TabbedForm,
  required,
  FormTab,
  useNotify,
  useTranslate,
  FunctionField,
  ImageField,
  ImageInput,
  useRefresh,
  SelectInput,
  ReferenceInput,
  useResourceDefinition,
} from "react-admin";
import MaterialTextField from "@mui/material/TextField";
import { url } from "../../request";
import { makeStyles } from "@mui/styles";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import SaveIcon from "@mui/icons-material/Save";
import swal from "sweetalert";
import moment from "moment";
import Switch from "@mui/material/Switch";
import { path } from "../../request";

import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import GetProductPrices from "./GetProductPrices";

export const Product_Edit = () => {
  const props = useResourceDefinition();
  const { id } = useParams();

  const [storeProductsDetails, setStoreProductsDetails] = React.useState([]);
  const [productSerialDetails, setProductSerialDetails] = React.useState([]);
  const [originalStoreProductsDetails, setOriginalStoreProductsDetails] =
    React.useState([]);
  const [tempStoreProductsDetails, setTempStoreProductsDetails] =
    React.useState([]);
  console.log(originalStoreProductsDetails);
  const [stores, setStores] = React.useState([]);

  const sc = localStorage.getItem("dollarPrice");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  async function getData() {
    await HTTP.get(`${url}/Products/${id}`).then((res) => {
      setOriginalStoreProductsDetails(res.data.storeProducts);
      setProductSerialDetails(res.data.productSerials);
    });
    await HTTP.get(`${url}/Stores?_start=0&_end=100`).then((res) => {
      setStores(res.data);
    });
  }

  const updateHandlerProductsDetialsForAll = (
    e,
    targetFiled,
    targetFiled2,
    isPriceChanging = false
  ) => {
    if (isPriceChanging) {
      if (parseFloat(e.target.value) >= 0) {
        originalStoreProductsDetails.map((store, key) => {
          if (store.purchaseByMain) {
            setOriginalStoreProductsDetails((prev) =>
              [(prev[key][targetFiled] = e.target.value), ...prev].slice(1)
            );
            setOriginalStoreProductsDetails((prev) =>
              [(prev[key][targetFiled2] = e.target.value * sc), ...prev].slice(
                1
              )
            );
          } else {
            setOriginalStoreProductsDetails((prev) =>
              [(prev[key][targetFiled] = e.target.value / sc), ...prev].slice(1)
            );
            setOriginalStoreProductsDetails((prev) =>
              [(prev[key][targetFiled2] = e.target.value), ...prev].slice(1)
            );
          }
        });
        tempStoreProductsDetails.map((store, key) => {
          if (store.purchaseByMain) {
            setTempStoreProductsDetails((prev) =>
              [(prev[key][targetFiled] = e.target.value), ...prev].slice(1)
            );
            setTempStoreProductsDetails((prev) =>
              [
                (prev[key][targetFiled2] = e.target.value * sc),
                ,
                ...prev,
              ].slice(1)
            );
          } else {
            setTempStoreProductsDetails((prev) =>
              [(prev[key][targetFiled] = e.target.value / sc), ...prev].slice(1)
            );
            setTempStoreProductsDetails((prev) =>
              [(prev[key][targetFiled2] = e.target.value), ...prev].slice(1)
            );
          }
        });
      }
    } else {
      originalStoreProductsDetails.map((store, key) => {
        setOriginalStoreProductsDetails((prev) =>
          [
            (prev[key][targetFiled] =
              e.target.type === "checkbox" ? e.target.checked : e.target.value),
            ...prev,
          ].slice(1)
        );
      });
      tempStoreProductsDetails.map((store, key) => {
        setTempStoreProductsDetails((prev) =>
          [
            (prev[key][targetFiled] =
              e.target.type === "checkbox" ? e.target.checked : e.target.value),
            ...prev,
          ].slice(1)
        );
      });
    }
  };

  const reset = (allStores) => {
    const tempStoreProducts = [];
    for (let i = 0; i < allStores.length; i++) {
      if (
        !originalStoreProductsDetails.find((o) => o.storeId === allStores[i].id)
      ) {
        tempStoreProducts.push({
          mcPurchasePrice: 0,
          scPurchasePrice: 0,
          mcSellPrice: 0,
          scSellPrice: 0,
          mcSmallMeasureSellPrice: 0,
          scSmallMeasureSellPrice: 0,
          scSellMinPrice: 0,
          mcSellMinPrice: 0,
          scSellMaxPrice: 0,
          mcSellMaxPrice: 0,
          alertQte: 0,
          expireDate: null,
          productionDate: null,
          purchaseByMain: false,
          sellByMain: false,
          storeId: allStores[i].storeId,
          productId: id,
          canSellBellowZero: true,
          store: allStores[i],
          storeId: allStores[i].id,
        });
      }
    }
    setTempStoreProductsDetails(tempStoreProducts);
  };

  React.useEffect(
    function () {
      getData();
    },
    [0]
  );
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Edit
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <TabbedForm>
        <FormTab label="resources.root.product">
          <TextInput
            source="barcode"
            label="resources.root.barcode"
            className={classes.input}
          />

          {process.env.REACT_APP_APP_NAME === "Mediply" ? (
            <TextInput
              source="name"
              label="resources.root.scientificName"
              className={classes.input}
              validate={[required()]}
            />
          ) : (
            <TextInput
              source="name"
              label="resources.root.name"
              className={classes.input}
              validate={[required()]}
            />
          )}

          {process.env.REACT_APP_APP_NAME === "Mediply" ? (
            <TextInput
              source="preferName"
              label="resources.root.BusinessName"
              className={classes.input}
              validate={[required()]}
            />
          ) : (
            <TextInput
              source="preferName"
              label="resources.root.preferedName"
              className={classes.input}
              validate={[required()]}
            />
          )}

          <ReferenceInput
            source="productCategoryId"
            label="resources.root.category"
            reference="ProductCategories"
            className={classes.input}
            perPage={500}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="brandId"
            label="resources.root.brand"
            reference="Brands"
            className={classes.input}
            perPage={500}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput
            source="shortDescription"
            label="resources.root.shortDescription"
            className={classes.input}
          />
          <TextInput
            source="longDescription"
            label="resources.root.longDescription"
            className={classes.input}
          />
          <TextInput
            source="productMeasure"
            label="resources.root.productMeasure"
            className={classes.input}
            validate={[required()]}
          />
          <NumberInput
            source="oneContains"
            label="resources.root.oneContains"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="oneMeasure"
            label="resources.root.oneMeasure"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="weightInKg"
            label="resources.root.weightInKg"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="note"
            label="resources.root.note"
            multiline
            className={classes.input}
          />
          <TextInput
            disabled
            source="initialQte"
            label="resources.root.initialQuantity"
            className={classes.input}
          />
          <ImageInput
            source="attachment"
            label="resources.root.attachment"
            accept="image/*"
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <FunctionField
            label="resources.root.attachment"
            render={(record) =>
              record.attachment !== null && (
                <div style={{ position: "relative" }}>
                  <button
                    type="button"
                    className="btn btn-danger p-2"
                    style={{ right: "0px", position: "absolute" }}
                    onClick={() => {
                      HTTP.delete(`${url}/Products/DeleteProductImage?id=${id}`)
                        .then(() => {
                          notify("Image deleted successfully", {
                            type: "success",
                            undoable: true,
                          });
                          refresh();
                        })
                        .catch((err) =>
                          notify(err.response.data.message, {
                            type: "error",
                            undoable: true,
                          })
                        );
                    }}
                  >
                    <span>&times;</span>
                  </button>
                  <img
                    style={{
                      width: 200,
                      height: 200,
                      objectFit: "scale-down",
                      cursor: "pointer",
                    }}
                    src={`${path}${record.attachment}`}
                    alt="Attachment"
                    onClick={() =>
                      window.open(`${path}${record.attachment}`, "_blank")
                    }
                  />
                </div>
              )
            }
          />
        </FormTab>
        <FormTab label="resources.root.barcodes">
          <TextInput
            source="barcode1"
            label="resources.root.barcode1"
            className={classes.input}
          />
          <TextInput
            source="barcode2"
            label="resources.root.barcode2"
            className={classes.input}
          />
          <TextInput
            source="barcode3"
            label="resources.root.barcode3"
            className={classes.input}
          />
          <TextInput
            source="barcode4"
            label="resources.root.barcode4"
            className={classes.input}
          />
          <TextInput
            source="barcode5"
            label="resources.root.barcode5"
            className={classes.input}
          />
          <TextInput
            source="barcode6"
            label="resources.root.barcode6"
            className={classes.input}
          />
          <TextInput
            source="barcode7"
            label="resources.root.barcode7"
            className={classes.input}
          />
          <TextInput
            source="barcode8"
            label="resources.root.barcode8"
            className={classes.input}
          />
          <TextInput
            source="barcode9"
            label="resources.root.barcode9"
            className={classes.input}
          />
        </FormTab>

        <FormTab label="resources.root.stores">
          <TableContainer component={Paper} className={classes.tableContainer}>
            <table className={"table table-hover text-center"}>
              <thead>
                <tr>
                  <td>{translate("resources.root.title")}</td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.purchasePrice")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.sellingPrice")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.smallSellPrice")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.minSell")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.maxSell")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.alertQuantity")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.expireDate")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.productionDate")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.purchaseByUSD")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.sellByUSD")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.canSellBelowZero")}
                  </td>
                  <td align="right" className={classes.tableHeader}>
                    {translate("resources.root.actions")}
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr hover>
                  <td component="th" scope="row">
                    {translate("resources.root.all")}
                  </td>
                  <td align="right">
                    <MaterialTextField
                      name="PurchasePrice"
                      type="number"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(
                          e,
                          "mcPurchasePrice",
                          "scPurchasePrice",
                          true
                        );
                      }}
                    />
                  </td>
                  <td align="right">
                    <MaterialTextField
                      name="SellPrice"
                      type="number"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(
                          e,
                          "mcSellPrice",
                          "scSellPrice",
                          true
                        );
                      }}
                    />
                  </td>
                  <td align="right">
                    <MaterialTextField
                      name="SmallMeasureSellPrice"
                      type="number"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(
                          e,
                          "mcSmallMeasureSellPrice",
                          "scSmallMeasureSellPrice",
                          true
                        );
                      }}
                    />
                  </td>{" "}
                  <td align="right">
                    <MaterialTextField
                      name="SellMinPrice"
                      type="number"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(
                          e,
                          "mcSellMinPrice",
                          "scSellMinPrice",
                          true
                        );
                      }}
                    />
                  </td>
                  <td align="right">
                    <MaterialTextField
                      name="SellMaxPrice"
                      type="number"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(
                          e,
                          "mcSellMaxPrice",
                          "scSellMaxPrice",
                          true
                        );
                      }}
                    />
                  </td>{" "}
                  <td align="right">
                    <MaterialTextField
                      name="alertQte"
                      type="number"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(e, "alertQte");
                      }}
                    />
                  </td>
                  <td align="right">
                    <Form.Group>
                      <Form.Control
                        name="expireDate"
                        className="w-100"
                        type="date"
                        onChange={(e) => {
                          updateHandlerProductsDetialsForAll(e, "expireDate");
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td align="right">
                    <Form.Group>
                      <Form.Control
                        name="productionDate"
                        className="w-100"
                        type="date"
                        onChange={(e) => {
                          updateHandlerProductsDetialsForAll(
                            e,
                            "productionDate"
                          );
                        }}
                      />
                    </Form.Group>
                  </td>
                  <td align="right">
                    <Switch
                      color="primary"
                      name="purchaseByMain"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(e, "purchaseByMain");
                      }}
                    />
                  </td>{" "}
                  <td align="right">
                    <Switch
                      color="primary"
                      name="sellByMain"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(e, "sellByMain");
                      }}
                    />
                  </td>
                  <td align="right">
                    <Switch
                      color="primary"
                      defaultChecked={false}
                      name="canSellBellowZero"
                      onChange={(e) => {
                        updateHandlerProductsDetialsForAll(
                          e,
                          "canSellBellowZero"
                        );
                      }}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </td>{" "}
                  <td align="right" className="d-flex">
                    <Snackbar
                      open={open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                    {tempStoreProductsDetails?.length > 0 && (
                      <IconButton
                        id={`saveButton`}
                        aria-label="resources.root.added"
                        onClick={() => {
                          {
                            tempStoreProductsDetails?.map((store) => {
                              if (
                                !store.alertQte &&
                                !store.mcSellPrice &&
                                !store.mcPurchasePrice &&
                                !store.mcSmallMeasureSellPrice
                              ) {
                                setErrorMessage("Please fill all the inputs.");
                                setOpen(true);
                                document.getElementById(
                                  "saveButton"
                                ).disabled = false;
                                return;
                              }
                              HTTP.post(`${url}/StoreProducts`, {
                                productId: id,
                                storeId: store.storeId,
                                mcPurchasePrice: store.mcPurchasePrice,
                                scPurchasePrice: store.scPurchasePrice,
                                mcSellPrice: store.mcSellPrice,
                                scSellPrice: store.scSellPrice,
                                mcSmallMeasureSellPrice:
                                  store.mcSmallMeasureSellPrice,
                                scSmallMeasureSellPrice:
                                  store.scSmallMeasureSellPrice,

                                // initialQte:
                                //   storeProductsDetails[key] &&
                                //   storeProductsDetails[key]["qteInStock"],
                                // productionDate:
                                //   storeProductsDetails[key] &&
                                //   storeProductsDetails[key]["productionDate"],
                                alertQte: store.alertQte,
                                expireDate: store?.expireDate,
                                productionDate: store?.productionDate,

                                purchaseByMain: store.purchaseByMain,

                                sellByMain: store.sellByMain,

                                canSellBellowZero: store.canSellBellowZero,
                              })
                                .then((res) => {
                                  notify("resources.root.added", {
                                    type: "success",
                                    undoable: true,
                                  });
                                  setTempStoreProductsDetails((prev) =>
                                    prev.filter((el) => el.id !== store.id)
                                  );
                                })
                                .then(() => {
                                  HTTP.get(`${url}/Products/${id}`).then(
                                    (res) => {
                                      setOriginalStoreProductsDetails(
                                        res.data.storeProducts
                                      );
                                    }
                                  );
                                })
                                .catch((err) =>
                                  notify(err.response.data.message, {
                                    type: "error",
                                    undoable: true,
                                  })
                                );
                            });
                          }
                        }}
                      >
                        <SaveIcon style={{ fontSize: "25px" }} />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="resources.root.delete"
                      onClick={() => {
                        swal({
                          title: translate("resources.root.makeSure"),
                          text: translate(
                            "resources.root.confirmPerminantDelete"
                          ),
                          icon: "warning",
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            {
                              originalStoreProductsDetails?.map((store) => {
                                HTTP.delete(`${url}/StoreProducts/${store.id}`)
                                  .then((res) => {
                                    notify(
                                      translate("resources.root.deleted"),
                                      {
                                        type: "success",
                                        undoable: true,
                                      }
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      prev.filter((el) => el.id !== store.id)
                                    );
                                  })
                                  .then((res) => {
                                    HTTP.get(`${url}/Products/${id}`).then(
                                      (res) => {
                                        setOriginalStoreProductsDetails(
                                          res.data.storeProducts
                                        );
                                      }
                                    );
                                  })
                                  .catch((err) =>
                                    notify(err.response.data.message, "error")
                                  );
                              });
                            }
                          } else {
                            swal(translate("resources.root.deleteCancel"));
                          }
                        });
                      }}
                    >
                      <DeleteIcon style={{ fontSize: "25px" }} />
                    </IconButton>
                    <IconButton
                      size={"medium"}
                      aria-label="resources.root.update"
                      onClick={() => {
                        originalStoreProductsDetails
                          ?.map((store) => {
                            HTTP.put(`${url}/StoreProducts/${store.id}`, {
                              id: store.id,
                              productId: id,
                              storeId: store.storeId,
                              mcPurchasePrice: store.mcPurchasePrice,
                              scPurchasePrice: store.scPurchasePrice,
                              mcSellPrice: store.mcSellPrice,
                              scSellPrice: store.scSellPrice,
                              mcSmallMeasureSellPrice:
                                store.mcSmallMeasureSellPrice,
                              scSmallMeasureSellPrice:
                                store.scSmallMeasureSellPrice,

                              // initialQte:
                              //   storeProductsDetails[key] &&
                              //   storeProductsDetails[key]["qteInStock"],
                              // productionDate:
                              //   storeProductsDetails[key] &&
                              //   storeProductsDetails[key]["productionDate"],
                              alertQte: store.alertQte,
                              expireDate: store?.expireDate,
                              productionDate: store?.productionDate,
                              purchaseByMain: store.purchaseByMain,
                              sellByMain: store.sellByMain,

                              canSellBellowZero: store.canSellBellowZero,
                            }).then(() => {
                              notify("resources.root.updated", {
                                type: "success",
                                undoable: true,
                              }).catch((err) =>
                                notify(err.response.data.message, {
                                  type: "error",
                                  undoable: true,
                                })
                              );
                            });
                          })
                          .then(() => {
                            HTTP.get(`${url}/Products/${id}`).then((res) => {
                              setOriginalStoreProductsDetails(
                                res.data.storeProducts
                              );
                            });
                          });
                      }}
                    >
                      <UpdateIcon style={{ fontSize: "25px" }} />
                    </IconButton>
                  </td>
                </tr>

                {originalStoreProductsDetails
                  .filter((el) => el.id != 0)
                  .map((store, key) => {
                    return (
                      <tr hover key={store.id}>
                        <td component="th" scope="row">
                          {store?.title || store?.store?.title}
                        </td>
                        <td align="right">
                          <MaterialTextField
                            name="PurchasePrice"
                            type="number"
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                if (store.purchaseByMain) {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcPurchasePrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scPurchasePrice"] =
                                        e.target.value * sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                } else {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcPurchasePrice"] =
                                        e.target.value / sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scPurchasePrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }
                            }}
                            value={
                              store.purchaseByMain
                                ? store.mcPurchasePrice
                                : store.scPurchasePrice
                            }
                          />
                        </td>
                        <td align="right">
                          <MaterialTextField
                            name="mcSellPrice"
                            type="number"
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                if (store.sellByMain) {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSellPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSellPrice"] =
                                        e.target.value * sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                } else {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSellPrice"] =
                                        e.target.value / sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSellPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }
                            }}
                            value={
                              store.sellByMain
                                ? store.mcSellPrice
                                : store.scSellPrice
                            }
                          />
                        </td>
                        <td align="right">
                          <MaterialTextField
                            name="mcSmallMeasureSellPrice"
                            type="number"
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                if (store.sellByMain) {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSmallMeasureSellPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSmallMeasureSellPrice"] =
                                        e.target.value * sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                } else {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSmallMeasureSellPrice"] =
                                        e.target.value / sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSmallMeasureSellPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }
                            }}
                            value={
                              store.sellByMain
                                ? store.mcSmallMeasureSellPrice
                                : store.scSmallMeasureSellPrice
                            }
                          />
                        </td>{" "}
                        <td align="right">
                          <MaterialTextField
                            name="minPrice"
                            type="number"
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                if (store.sellByMain) {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSellMinPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSellMinPrice"] =
                                        e.target.value * sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                } else {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSellMinPrice"] =
                                        e.target.value / sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSellMinPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }
                            }}
                            value={
                              store.sellByMain
                                ? store.mcSellMinPrice
                                : store.scSellMinPrice
                            }
                          />
                        </td>{" "}
                        <td align="right">
                          <MaterialTextField
                            name="MaxPrice"
                            type="number"
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                if (store.sellByMain) {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSellMaxPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSellMaxPrice"] =
                                        e.target.value * sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                } else {
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["mcSellMaxPrice"] =
                                        e.target.value / sc),
                                      ...prev,
                                    ].slice(1)
                                  );
                                  setOriginalStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["scSellMaxPrice"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }
                            }}
                            value={
                              store.sellByMain
                                ? store.mcSellMaxPrice
                                : store.scSellMaxPrice
                            }
                          />
                        </td>{" "}
                        <td align="right">
                          <MaterialTextField
                            name="alertQte"
                            type="number"
                            onChange={(e) => {
                              if (parseFloat(e.target.value) >= 0) {
                                setOriginalStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["alertQte"] = e.target.value),
                                    ...prev,
                                  ].slice(1)
                                );
                              }
                            }}
                            value={store.alertQte}
                          />
                        </td>
                        <td align="right">
                          <Form.Group>
                            <Form.Control
                              className="w-100"
                              type="date"
                              value={moment(store.expireDate).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                setOriginalStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["expireDate"] = e.target.value),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                            />
                          </Form.Group>
                        </td>
                        <td align="right">
                          <Form.Group>
                            <Form.Control
                              className="w-100"
                              type="date"
                              value={moment(store.productionDate).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={(e) => {
                                setOriginalStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["productionDate"] =
                                      e.target.value),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                            />
                          </Form.Group>
                        </td>
                        <td align="right">
                          <Switch
                            color="primary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            onChange={(e) => {
                              setOriginalStoreProductsDetails((prev) =>
                                [
                                  (prev[key]["purchaseByMain"] =
                                    e.target.checked),
                                  ...prev,
                                ].slice(1)
                              );
                            }}
                            checked={store.purchaseByMain}
                          />
                        </td>{" "}
                        <td align="right">
                          <Switch
                            color="primary"
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            onChange={(e) => {
                              setOriginalStoreProductsDetails((prev) =>
                                [
                                  (prev[key]["sellByMain"] = e.target.checked),
                                  ...prev,
                                ].slice(1)
                              );
                            }}
                            checked={store.sellByMain}
                          />
                        </td>
                        <td align="right">
                          <Switch
                            color="primary"
                            defaultChecked={false}
                            name="checkedB"
                            inputProps={{ "aria-label": "primary checkbox" }}
                            onChange={(e) => {
                              setOriginalStoreProductsDetails((prev) =>
                                [
                                  (prev[key]["canSellBellowZero"] =
                                    e.target.checked),
                                  ...prev,
                                ].slice(1)
                              );
                            }}
                            checked={store.canSellBellowZero}
                          />
                        </td>
                        <td align="right" className="d-flex">
                          <Snackbar
                            open={open}
                            autoHideDuration={4000}
                            onClose={handleClose}
                          >
                            <Alert onClose={handleClose} severity="error">
                              {errorMessage}
                            </Alert>
                          </Snackbar>
                          <IconButton
                            aria-label="resources.root.delete"
                            onClick={() => {
                              swal({
                                title: translate("resources.root.makeSure"),
                                text: translate(
                                  "resources.root.confirmPerminantDelete"
                                ),
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((willDelete) => {
                                if (willDelete) {
                                  HTTP.delete(
                                    `${url}/StoreProducts/${store.id}`
                                  )
                                    .then((res) => {
                                      notify("resources.root.deleted", {
                                        type: "success",
                                        undoable: true,
                                      });
                                      setTempStoreProductsDetails((prev) =>
                                        prev.filter((el) => el.id !== store.id)
                                      );
                                    })
                                    .then((res) => {
                                      HTTP.get(`${url}/Products/${id}`).then(
                                        (res) => {
                                          setOriginalStoreProductsDetails(
                                            res.data.storeProducts
                                          );
                                        }
                                      );
                                    })
                                    .catch((err) =>
                                      notify(err.response.data.message, "error")
                                    );
                                } else {
                                  swal(
                                    translate("resources.root.deleteCancel")
                                  );
                                }
                              });
                            }}
                          >
                            <DeleteIcon style={{ fontSize: "25px" }} />
                          </IconButton>
                          <IconButton
                            size={"medium"}
                            aria-label="resources.root.update"
                            onClick={() => {
                              HTTP.put(`${url}/StoreProducts/${store.id}`, {
                                id: store.id,
                                productId: id,
                                storeId: store.storeId,
                                mcPurchasePrice: store.mcPurchasePrice,
                                scPurchasePrice: store.scPurchasePrice,
                                mcSellPrice: store.mcSellPrice,
                                scSellPrice: store.scSellPrice,
                                mcSmallMeasureSellPrice:
                                  store.mcSmallMeasureSellPrice,
                                scSmallMeasureSellPrice:
                                  store.scSmallMeasureSellPrice,
                                mcSellMinPrice: store.mcSellMinPrice,
                                scSellMinPrice: store.scSellMinPrice,
                                mcSellMaxPrice: store.mcSellMaxPrice,
                                scSellMaxPrice: store.scSellMaxPrice,
                                alertQte: store.alertQte,
                                expireDate: store?.expireDate,
                                productionDate: store?.productionDate,
                                purchaseByMain: store.purchaseByMain,
                                sellByMain: store.sellByMain,

                                canSellBellowZero: store.canSellBellowZero,
                              })
                                .then(() => {
                                  notify("resources.root.updated", {
                                    type: "success",
                                    undoable: true,
                                  });
                                  HTTP.get(`${url}/Products/${id}`).then(
                                    (res) => {
                                      setOriginalStoreProductsDetails(
                                        res.data.storeProducts
                                      );
                                    }
                                  );
                                  notify(translate("resources.root.updated"));
                                })
                                .catch((err) =>
                                  notify(err.response.data.message, {
                                    type: "error",
                                    undoable: true,
                                  })
                                );
                            }}
                          >
                            <UpdateIcon style={{ fontSize: "25px" }} />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td colSpan={13}>
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      onClick={() => reset(stores)}
                    >
                      Add another store
                    </button>
                  </td>
                </tr>
                {tempStoreProductsDetails &&
                  tempStoreProductsDetails
                    .filter((el) => el.id != 0)
                    .map((store, key) => {
                      return (
                        <tr hover key={store.id}>
                          <td component="th" scope="row">
                            {store?.title || store?.store?.title}
                          </td>
                          <td align="right">
                            <MaterialTextField
                              name="mcPurchasePrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (store.purchaseByMain) {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcPurchasePrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scPurchasePrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcPurchasePrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scPurchasePrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                store.purchaseByMain
                                  ? store.mcPurchasePrice
                                  : store.scPurchasePrice
                              }
                            />
                          </td>
                          <td align="right">
                            <MaterialTextField
                              name="mcSellPrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (store.sellByMain) {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellPrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellPrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                store.sellByMain
                                  ? store.mcSellPrice
                                  : store.scSellPrice
                              }
                            />
                          </td>
                          <td align="right">
                            <MaterialTextField
                              name="mcSmallMeasureSellPrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (store.sellByMain) {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSmallMeasureSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSmallMeasureSellPrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSmallMeasureSellPrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSmallMeasureSellPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                store.sellByMain
                                  ? store.mcSmallMeasureSellPrice
                                  : store.scSmallMeasureSellPrice
                              }
                            />
                          </td>{" "}
                          <td align="right">
                            <MaterialTextField
                              name="MinPrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (store.sellByMain) {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellMinPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellMinPrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellMinPrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellMinPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                store.sellByMain
                                  ? store.mcSellMinPrice
                                  : store.scSellMinPrice
                              }
                            />
                          </td>{" "}
                          <td align="right">
                            <MaterialTextField
                              name="MaxPrice"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  if (store.sellByMain) {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellMaxPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellMaxPrice"] =
                                          e.target.value * sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  } else {
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["mcSellMaxPrice"] =
                                          e.target.value / sc),
                                        ...prev,
                                      ].slice(1)
                                    );
                                    setTempStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["scSellMaxPrice"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }
                              }}
                              value={
                                store.sellByMain
                                  ? store.mcSellMaxPrice
                                  : store.scSellMaxPrice
                              }
                            />
                          </td>{" "}
                          <td align="right">
                            <MaterialTextField
                              name="alertQte"
                              type="number"
                              onChange={(e) => {
                                if (parseFloat(e.target.value) >= 0) {
                                  setTempStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["alertQte"] = e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }
                              }}
                              value={store.alertQte}
                            />
                          </td>
                          <td align="right">
                            <Form.Group>
                              <Form.Control
                                className="w-100"
                                type="date"
                                value={moment(store.expireDate).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => {
                                  setTempStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["expireDate"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                              />
                            </Form.Group>
                          </td>
                          <td align="right">
                            <Form.Group>
                              <Form.Control
                                className="w-100"
                                type="date"
                                value={moment(store.productionDate).format(
                                  "YYYY-MM-DD"
                                )}
                                onChange={(e) => {
                                  setTempStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["productionDate"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                              />
                            </Form.Group>
                          </td>
                          <td align="right">
                            <Switch
                              color="primary"
                              name="checkedB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e) => {
                                setTempStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["purchaseByMain"] =
                                      e.target.checked),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                              checked={store.purchaseByMain}
                            />
                          </td>{" "}
                          <td align="right">
                            <Switch
                              color="primary"
                              name="checkedB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e) => {
                                setTempStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["sellByMain"] =
                                      e.target.checked),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                              checked={store.sellByMain}
                            />
                          </td>
                          <td align="right">
                            <Switch
                              color="primary"
                              defaultChecked={false}
                              name="checkedB"
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={(e) => {
                                setTempStoreProductsDetails((prev) =>
                                  [
                                    (prev[key]["canSellBellowZero"] =
                                      e.target.checked),
                                    ...prev,
                                  ].slice(1)
                                );
                              }}
                              checked={store.canSellBellowZero}
                            />
                          </td>
                          <td align="right" className="d-flex">
                            <Snackbar
                              open={open}
                              autoHideDuration={4000}
                              onClose={handleClose}
                            >
                              <Alert onClose={handleClose} severity="error">
                                {errorMessage}
                              </Alert>
                            </Snackbar>

                            <IconButton
                              id={`saveButton`}
                              onClick={() => {
                                if (
                                  !store.alertQte &&
                                  !store.mcSellPrice &&
                                  !store.mcPurchasePrice &&
                                  !store.mcSmallMeasureSellPrice
                                ) {
                                  setErrorMessage(
                                    "Please fill all the inputs."
                                  );
                                  setOpen(true);
                                  document.getElementById(
                                    "saveButton"
                                  ).disabled = false;
                                  return;
                                }
                                HTTP.post(`${url}/StoreProducts`, {
                                  productId: id,
                                  storeId: store.storeId,
                                  mcPurchasePrice: store.mcPurchasePrice,
                                  scPurchasePrice: store.scPurchasePrice,
                                  mcSellPrice: store.mcSellPrice,
                                  scSellPrice: store.scSellPrice,
                                  mcSmallMeasureSellPrice:
                                    store.mcSmallMeasureSellPrice,
                                  scSmallMeasureSellPrice:
                                    store.scSmallMeasureSellPrice,

                                  // initialQte:
                                  //   storeProductsDetails[key] &&
                                  //   storeProductsDetails[key]["qteInStock"],
                                  // productionDate:
                                  //   storeProductsDetails[key] &&
                                  //   storeProductsDetails[key]["productionDate"],
                                  alertQte: store.alertQte,
                                  expireDate: store?.expireDate,
                                  productionDate: store?.productionDate,
                                  purchaseByMain: store.purchaseByMain,

                                  sellByMain: store.sellByMain,

                                  canSellBellowZero: store.canSellBellowZero,
                                })
                                  .then((res) => {
                                    notify("resources.root.added", {
                                      type: "success",
                                      undoable: true,
                                    });
                                    setTempStoreProductsDetails((prev) =>
                                      prev.filter((el) => el.id !== store.id)
                                    );
                                  })
                                  .then(() => {
                                    HTTP.get(`${url}/Products/${id}`).then(
                                      (res) => {
                                        setOriginalStoreProductsDetails(
                                          res.data.storeProducts
                                        );
                                      }
                                    );
                                  })
                                  .catch((err) =>
                                    notify(err.response.data.message, {
                                      type: "error",
                                      undoable: true,
                                    })
                                  );
                              }}
                            >
                              <SaveIcon style={{ fontSize: "25px" }} />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </TableContainer>
        </FormTab>
        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
          <FormTab label="resources.root.serials">
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table stickyHeader className={classes.table} size="small">
                <TableHead>
                  <TableRow hover>
                    <TableCell>{translate("resources.root.id")}</TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.serial")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.productionDate")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.expireDate")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.alertQuantity")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.canSellBelowZero")}
                    </TableCell>
                    <TableCell align="right" className={classes.tableHeader}>
                      {translate("resources.root.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {productSerialDetails &&
                    productSerialDetails
                      .filter((el) => el.id !== 0)
                      .map((serial, key) => {
                        return (
                          <TableRow hover key={serial.id}>
                            <TableCell component="th" scope="row">
                              {serial.id || serial.store.id}
                            </TableCell>
                            <TableCell align="right">
                              <MaterialTextField
                                name="serialNumber"
                                disabled
                                onChange={(e) => {
                                  setProductSerialDetails((prev) =>
                                    [
                                      (prev[key]["serialNumber"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                value={
                                  productSerialDetails[key] &&
                                  productSerialDetails[key]["serialNumber"]
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                style={{
                                  marginRight: 5,
                                  padding: 6,
                                  borderRadius: 4,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderColor: "gray",
                                  width: "100%",
                                }}
                                type="date"
                                label="resources.root.productionDate"
                                source="productionDate"
                                alwaysOn
                                onChange={(e) => {
                                  setProductSerialDetails((prev) =>
                                    [
                                      (prev[key]["productionDate"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                defaultValue={
                                  productSerialDetails[key] &&
                                  moment(
                                    productSerialDetails[key]["productionDate"]
                                  ).format("YYYY-MM-DD")
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <input
                                style={{
                                  marginRight: 5,
                                  padding: 6,
                                  borderRadius: 4,
                                  borderWidth: 1,
                                  borderStyle: "solid",
                                  borderColor: "gray",
                                  width: "100%",
                                }}
                                type="date"
                                label="resources.root.expirationDate"
                                source="expirationDate"
                                alwaysOn
                                onChange={(e) => {
                                  setProductSerialDetails((prev) =>
                                    [
                                      (prev[key]["expirationDate"] =
                                        e.target.value),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                defaultValue={
                                  productSerialDetails[key] &&
                                  moment(
                                    productSerialDetails[key]["expirationDate"]
                                  ).format("YYYY-MM-DD")
                                }
                              />
                            </TableCell>

                            <TableCell align="right">
                              <MaterialTextField
                                name="alertQte"
                                type="number"
                                onChange={(e) => {
                                  if (parseFloat(e.target.value) >= 0) {
                                    setStoreProductsDetails((prev) =>
                                      [
                                        (prev[key]["alertQte"] =
                                          e.target.value),
                                        ...prev,
                                      ].slice(1)
                                    );
                                  }
                                }}
                                value={
                                  productSerialDetails[key] &&
                                  productSerialDetails[key]["alertQte"]
                                }
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Switch
                                color="primary"
                                defaultChecked={false}
                                name="checkedB"
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                onChange={(e) => {
                                  setStoreProductsDetails((prev) =>
                                    [
                                      (prev[key]["canSellBellowZero"] =
                                        e.target.checked),
                                      ...prev,
                                    ].slice(1)
                                  );
                                }}
                                checked={
                                  productSerialDetails[key] &&
                                  productSerialDetails[key]["canSellBellowZero"]
                                }
                              />
                            </TableCell>
                            <TableCell align="right" className="d-flex">
                              <Snackbar
                                open={open}
                                autoHideDuration={4000}
                                onClose={handleClose}
                              >
                                <Alert onClose={handleClose} severity="error">
                                  {errorMessage}
                                </Alert>
                              </Snackbar>
                              {serial.id !== 0 && (
                                <IconButton
                                  aria-label="resources.root.delete"
                                  onClick={() => {
                                    swal({
                                      title: translate(
                                        "resources.sellInvoices.fields.makeSure"
                                      ),
                                      text: translate(
                                        "resources.sellInvoices.fields.confirmPerminantDelete"
                                      ),
                                      icon: "warning",
                                      buttons: true,
                                      dangerMode: true,
                                    }).then((willDelete) => {
                                      if (willDelete) {
                                        HTTP.delete(
                                          `${url}/ProductSerials/${
                                            productSerialDetails[key] &&
                                            productSerialDetails[key]["id"]
                                          }`
                                        )
                                          .then((res) => {
                                            setTempStoreProductsDetails(
                                              (prev) =>
                                                prev.filter(
                                                  (el) => el.id !== serial.id
                                                )
                                            );
                                          })
                                          .then((res) => {
                                            HTTP.get(
                                              `${url}/Products/${id}`
                                            ).then((res) => {
                                              setProductSerialDetails(
                                                res.data.ProductSerials
                                              );
                                            });
                                          })
                                          .catch((err) =>
                                            notify(
                                              err.response.data.message,
                                              "error"
                                            )
                                          );
                                      } else {
                                        swal(
                                          translate(
                                            "resources.sellInvoices.fields.deleteCancel"
                                          )
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <DeleteIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                              )}
                              {serial.id !== 0 ? (
                                <IconButton
                                  size={"medium"}
                                  aria-label="resources.root.update"
                                  onClick={() => {
                                    HTTP.put(
                                      `${url}/ProductSerials/${
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["id"]
                                      }`,
                                      {
                                        id:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key]["id"],
                                        productId: id,
                                        storeId:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key]["storeId"],
                                        serialNumber:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "serialNumber"
                                          ],
                                        productionDate:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "productionDate"
                                          ],
                                        expirationDate:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "expirationDate"
                                          ],
                                        alertQte:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key]["alertQte"],
                                        canSellBellowZero:
                                          productSerialDetails[key] &&
                                          productSerialDetails[key][
                                            "canSellBellowZero"
                                          ],
                                      }
                                    )
                                      .then(() => {
                                        HTTP.get(`${url}/Products/${id}`).then(
                                          (res) => {
                                            setProductSerialDetails(
                                              res.data.productSerials
                                            );
                                          }
                                        );
                                        notify(
                                          translate("resources.root.updated")
                                        );
                                      })
                                      .catch((err) =>
                                        notify(err.response.data.message, {
                                          type: "error",
                                          undoable: true,
                                        })
                                      );
                                  }}
                                >
                                  <UpdateIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                              ) : (
                                <IconButton
                                  id={`saveButton`}
                                  aria-label="resources.root.save"
                                  onClick={() => {
                                    document.getElementById(
                                      "saveButton"
                                    ).disabled = true;
                                    if (
                                      productSerialDetails[key] &&
                                      !productSerialDetails[key]["alertQte"]
                                    ) {
                                      setErrorMessage(
                                        "Please fill in the Alert Quantity."
                                      );
                                      setOpen(true);
                                      document.getElementById(
                                        "saveButton"
                                      ).disabled = false;
                                      return;
                                    } else if (
                                      productSerialDetails[key] &&
                                      !(
                                        productSerialDetails[key][
                                          "mcSellPrice"
                                        ] ||
                                        productSerialDetails[key]["scSellPrice"]
                                      )
                                    ) {
                                      setErrorMessage(
                                        "Please fill in Selling Price."
                                      );
                                      setOpen(true);
                                      document.getElementById(
                                        "saveButton"
                                      ).disabled = false;
                                      return;
                                    }
                                    HTTP.post(`${url}/ProductSerials`, {
                                      productId: id,
                                      storeId:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["id"],
                                      serialNumber:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "serialNumber"
                                        ],
                                      productionDate:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "productionDate"
                                        ],
                                      expirationDate:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "expirationDate"
                                        ],
                                      alertQte:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["alertQte"],
                                      canSellBellowZero:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key][
                                          "canSellBellowZero"
                                        ],

                                      alertQte:
                                        productSerialDetails[key] &&
                                        productSerialDetails[key]["alertQte"],
                                      canSellBellowZero:
                                        (productSerialDetails[key] &&
                                          typeof productSerialDetails[key][
                                            "canSellBellowZero"
                                          ]) === "undefined"
                                          ? false
                                          : productSerialDetails[key][
                                              "canSellBellowZero"
                                            ],
                                    })
                                      .then((res) => {
                                        setTempStoreProductsDetails((prev) =>
                                          prev.filter(
                                            (el) => el.id !== serial.id
                                          )
                                        );
                                      })
                                      .then(() => {
                                        HTTP.get(`${url}/Products/${id}`).then(
                                          (res) => {
                                            setOriginalStoreProductsDetails(
                                              res.data.storeProducts
                                            );
                                          }
                                        );
                                        document.getElementById(
                                          "saveButton"
                                        ).disabled = false;
                                      })
                                      .catch((err) =>
                                        notify(err.response.data.message, {
                                          type: "error",
                                          undoable: true,
                                        })
                                      );
                                    document.getElementById(
                                      "saveButton"
                                    ).disabled = false;
                                  }}
                                >
                                  <SaveIcon style={{ fontSize: "25px" }} />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </FormTab>
        )}

        <FormTab label="resources.root.prices">
          <GetProductPrices />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
const useStyles = makeStyles((theme) => ({
  input: {
    width: "30%",
  },
  formContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  perColumnInput: {
    flex: 1,
    marginLeft: 5,
  },
  productInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
  },
  storeProductInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    marginBottom: 10,
    flexWrap: "wrap",
    "&:after": {
      content: "''",
      flex: 1,
    },
  },
  sampleStoreProductInputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",
    borderBottomWidth: 1,
    marginBottom: 10,
    backgroundColor: "#f2f2f2",
    flexWrap: "wrap",
    paddingTop: 10,
    "&:after": {
      content: "''",
      flex: 1,
    },
  },
  productInputsColumn: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 5,
  },
  ProductsInput: {
    marginRight: 10,
    width: "15%",
  },
  root: {
    marginTop: 72,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  productCols: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 10,
  },
  productNumberInput: {
    width: "15% !important",
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  productTextInput: {
    width: "20% !important",
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
  },
  productLongTextInput: {
    width: "25% !important",
    marginLeft: 10,
  },
  tableHeader: {
    textAlign: "center",
  },
  tableContainer: {
    width: "100%",
  },
  primary: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
  },
  secondary: {
    // backgroundColor: 'rgb(220, 0, 78)',
    color: "rgb(220, 0, 78)",
  },
  switchLabel: {
    fontFamily: "monospace",
  },
}));
