import { permissions } from "./AllPermission";

// Saffen permissions for GeneralAccountatn, Accountatn, and Cashier is the same for the sake of good management there is the array of
// permissions for all of them in case they change it any permission for one of be aware of it
const samePermissions = [
  //! Dashboard
  permissions.dashboard,
  permissions.dashboardCashers,
  permissions.dashboardTabs,
  permissions.dashboardSafeboxes,

  //? product section
  //! product
  permissions.productList,
  permissions.productCreate,
  permissions.productView,

  //? customer section
  //! customer
  permissions.customerList,
  permissions.customerCreate,
  permissions.customerView,

  //! customer payment
  permissions.customerPaymentList,
  permissions.customerPaymentCreate,
  permissions.customerPaymentView,

  //? sell invoice section
  //! Sell Invoice
  permissions.sellInvoiceList,
  permissions.sellInvoiceCreate,
  permissions.sellInvoiceView,

  //! Sell return Invoice
  permissions.returnSellInvoiceList,
  permissions.returnSellInvoiceCreate,
  permissions.returnSellInvoiceView,

  //? supplier section
  //! Supplier
  permissions.supplierList,
  permissions.supplierCreate,
  permissions.supplierView,

  //! Supplier payment
  permissions.supplierPaymentList,
  permissions.supplierPaymentCreate,
  permissions.supplierPaymentView,

  //? purchase invoice section
  //! purchase Invoice
  permissions.purchaseInvoiceList,
  permissions.purchaseInvoiceCreate,
  permissions.purchaseInvoiceView,

  //! purchase return Invoice
  permissions.returnPurchaseInvoiceList,
  permissions.returnPurchaseInvoiceCreate,
  permissions.returnPurchaseInvoiceView,

  //? Cashier section
  //! sells
  permissions.cashierSellList,
  //! Return
  permissions.cashierReturnList,
  //! WithDraws
  permissions.cashierWithdrawList,
  permissions.cashierWithdrawView,

  //? HR section
  //! HR
  permissions.HrList,

  //? Accountant section
  //! Bank Accounts
  permissions.bankAccountList,
  permissions.bankAccountView,

  //!  Bank Account Transfer
  permissions.bankAccountTransferList,

  //! User Store Transaction Histories
  permissions.userStoreTransactionHistoriesList,

  //! User Store Bank Account Transfer
  permissions.userStoreBankAccountTransferList,
  permissions.userStoreBankAccountTransferCreate,
  permissions.userStoreBankAccountTransferView,

  //! Currency exchange
  permissions.currencyExchangeList,
  permissions.currencyExchangeCreate,
  permissions.currencyExchangeView,

  //! expense
  permissions.expenseList,

  // ? Setting
  // ! setting
  permissions.setting,

  // ? Reports module
  // ! General Report
  permissions.generalReport,
  // ! HR Reports
  permissions.hrReport,
  // ! Item Report Reports
  permissions.itemReport,
  // ! Supplier Report
  permissions.supplierReport,
];
const accountantPermissions = [
  //! process Invoice
  permissions.processInvoiceList,
];
export default {
  storeadmin: ["*"],
  superadmin: ["*"],
  admin: ["*"],
  generalaccountant: samePermissions,

  accountant: samePermissions.concat(accountantPermissions),
  casher: samePermissions,
  debtcollector: [
    //? product section
    //! product
    permissions.productList,
    permissions.productView,

    //? customer section
    //! customer
    permissions.customerList,
    permissions.customerView,
  ],
};
