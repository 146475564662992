import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FiCheck } from "react-icons/fi";
import { useTranslate } from "react-admin";
import moment, { locale } from "moment";

const HalfPage = ({ data }) => {
  const mainColor =
    data?.type === "SupplierPayment"
      ? localStorage.getItem("mainColorSupplier")
      : data?.type === "CustomerPayment"
      ? localStorage.getItem("mainColorCustomer")
      : data?.type === "EmployeeLoan"
      ? localStorage.getItem("mainColorEmployeeLoan")
      : data?.type === "Expense"
      ? localStorage.getItem("mainColorExpense")
      : data?.type === "Withdraw"
      ? localStorage.getItem("mainColorWithdraw")
      : data?.type === "Deposit"
      ? localStorage.getItem("mainColorDeposit")
      : data?.type === "EmployeeReturnLoan"
      ? localStorage.getItem("mainColorEmployeeReturnLoan")
      : "#14a44d";

  const oldLoan =
    (parseFloat(data?.amount) || 0) +
    (parseFloat(data?.forgivePrice) || 0) +
    (parseFloat(data?.currentLoan) || 0);

  const hrVoucher = {
    border: `1px solid ${mainColor}`,
  };

  const bgColor = {
    backgroundColor: `${mainColor}`,
  };

  const textColor = {
    color: `${mainColor}`,
  };

  console.log(data);
  const logo = JSON.parse(localStorage.getItem("logo2"));
  const translate = useTranslate();
  return process.env.REACT_APP_APP_NAME !== "Sherko" &&
    process.env.REACT_APP_APP_NAME !== "SherkoNew" ? (
    <Fragment>
      <div
        className="position-absolute greenColor"
        style={{ marginTop: `10px`, fontSize: `20px`, fontWeight: `bold` }}
      >
        {localStorage.getItem("infoRefresh1Industry") || "Industry"}
        <div
          className="position-absolute"
          style={{
            marginStart: `${localStorage.getItem("phone1Xaxis") || 0}px`,
            marginTop: `${localStorage.getItem("phone1Yaxis") || 15}px`,
            fontSize: `${localStorage.getItem("phone1Size") || 15}px`,
            fontWeight: `${
              localStorage.getItem("bold1headerBold") === "true"
                ? "bold"
                : "normal"
            }`,
          }}
        >
          {localStorage.getItem("infoRefresh1Phone1") || "07508682031"}
          <br />
          {localStorage.getItem("infoRefresh1Phone2") || "07508682031"}
        </div>
      </div>
      <div
        className="d-flex position-absolute"
        style={{
          marginInlineStart: `${localStorage.getItem("logo1Xaxis") || 380}px`,
          marginTop: `${localStorage.getItem("logo1Yaxis") || 10}px`,
        }}
      >
        <img
          className="img-fluid object-contain"
          style={{
            borderRadius: `${localStorage.getItem("logo1Radius") || 25}%`,
            width: `${localStorage.getItem("logo1Width") || 100}px`,
            height: `${localStorage.getItem("logo1Height") || 100}px`,
          }}
          src={logo?.Url || "https://i.ibb.co/k0X12vp/logo.jpg"}
          alt="logo"
        />
      </div>

      <div
        className="d-block position-absolute greenColor"
        style={{
          marginInlineStart: `${localStorage.getItem("info1Xaxis") || 600}px`,
          marginTop: `${localStorage.getItem("info1Yaxis") || 10}px`,
          fontSize: `${localStorage.getItem("info1Size") || 17}px`,
          fontWeight: `${
            localStorage.getItem("bold1headerBold") === "true"
              ? "bold"
              : "normal"
          }`,
          textAlign:
            localStorage.getItem("direction") === "rtl" ? "right" : "left",
        }}
      >
        <div
          style={{
            fontWeight: `${
              localStorage.getItem("bold1headerBold") === "true"
                ? "bold"
                : "normal"
            }`,
          }}
        >
          {localStorage.getItem("direction") === "rtl"
            ? `${
                (data?.invoiceNo && data?.invoiceNo.toUpperCase()) ||
                "Invoice No"
              }: ${translate("resources.root.invoiceNumber")}`
            : `${translate("resources.root.invoiceNumber")}: ${
                (data?.invoiceNo && data?.invoiceNo.toUpperCase()) ||
                "Invoice No"
              }`}
        </div>
        <div
          className={
            localStorage.getItem(`show1serial`) === "true" ? null : "d-none"
          }
        >
          {translate("resources.root.id")}:{" "}
          {(data?.serialNo && data?.serialNo?.toLocaleString()) || "0"}
        </div>
        <div
          className={
            localStorage.getItem(`show1serial`) === "true" ? null : "d-none"
          }
        >
          {translate("resources.root.id")}:{" "}
          {(data?.serialNo && data?.serialNo?.toLocaleString()) || "0"}
        </div>
      </div>
      <div
        className="mb-5 px-3 header greenColor"
        style={{
          marginTop: "150px",
          fontWeight: `${
            localStorage.getItem("bold1headerBold") === "true"
              ? "bold"
              : "normal"
          }`,
        }}
      >
        <div
          className="position-absolute"
          style={{
            marginInlineStart: `${
              localStorage.getItem("headerLine1AXaxis") || 0
            }px`,
            marginTop: `${localStorage.getItem("headerLine1AYaxis") || 0}px`,
            fontSize: `${localStorage.getItem("headerLine1ASize") || 18}px`,
            fontWeight: `${
              localStorage.getItem("bold1headerBold") === "true"
                ? "bold"
                : "normal"
            }`,
          }}
        >
          {localStorage.getItem("infoRefresh1Address") || "Address"}
        </div>
        <div
          className="position-absolute"
          style={{
            marginInlineStart: `${
              localStorage.getItem("headerLine1BXaxis") || 550
            }px`,
            marginTop: `${localStorage.getItem("headerLine1BYaxis") || 0}px`,
            fontSize: `${localStorage.getItem("headerLine1BSize") || 18}px`,
            fontWeight: `${
              localStorage.getItem("bold1headerBold") === "true"
                ? "bold"
                : "normal"
            }`,
          }}
        >
          {localStorage.getItem("infoRefresh1Email")
            ? "Email: " + localStorage.getItem("infoRefresh1Email")
            : "Email: example@example.com"}
        </div>
      </div>
      <div className="static mt-5 mb-5" style={{ fontSize: "17px" }}>
        <Row className="mb-5">
          <Col className="TextLeft">
            <div className="d-flex justify-content-start">
              <div className="squaree">IQD</div>
              <div className="squaree">{data?.dollar ? null : <FiCheck />}</div>
            </div>
            <div className="d-flex justify-content-start">
              <div className="squaree">USD</div>
              <div className="squaree">{data?.dollar ? <FiCheck /> : null}</div>
            </div>
          </Col>
          <Col
            className="text-center"
            style={{
              fontWeight: `${
                localStorage.getItem("bold1bodyBold") === "true"
                  ? "bold"
                  : "normal"
              }`,
            }}
          >
            {data?.isDeposit ? "پسولەی وەرگرتن" : "پسولەی پارەدان"}
            <br />
            {data?.isDeposit ? "Receipt Voucher" : "Payment Voucher"}
          </Col>
          <Col className="textRight">
            ڕێکەوت :
            <span
              style={{
                fontWeight: `${
                  localStorage.getItem("bold1bodyBold") === "true"
                    ? "bold"
                    : "normal"
                }`,
              }}
            >
              {moment(data?.date).format("YYYY-MM-DD") || "24.08.2021"}
            </span>
          </Col>
        </Row>
        <div className="mb-4 d-flex justify-content-between">
          <div className="TextLeft">
            {data?.isDeposit ? "Received From: " : "Payed To: "}
            <span
              style={{
                fontWeight: `${
                  localStorage.getItem("bold1bodyBold") === "true"
                    ? "bold"
                    : "normal"
                }`,
              }}
            >
              {data?.payedTo || "Default"}
            </span>
          </div>
          <div className="textRight">
            {data?.isDeposit ? " : وەرگیرا لە" : " : (اعطی الی)درا بە"}
          </div>
        </div>
        <div className="mb-4 d-flex justify-content-between">
          <div className="TextLeft">
            {localStorage.getItem("infoRefresh1EnglishAmount") || "Amount"}
            :&nbsp;
            <span
              style={{
                fontWeight: `${
                  localStorage.getItem("bold1bodyBold") === "true" || "normal"
                }`,
              }}
            >
              {(data?.amount && data?.amount?.toLocaleString()) || "Amount"}{" "}
              {data?.dollar ? "USD" : "IQD"}
              {data?.type === "SupplierPayment" ||
              data?.type === "CustomerPayment" ||
              data?.type === "RepresentativePayment" ? (
                <span
                  className={
                    localStorage.getItem("show1loan") === "true"
                      ? null
                      : "d-none"
                  }
                >
                  ,&nbsp;
                  {`${translate("resources.root.currentLoan")} : ${
                    data?.currentLoan
                  }`}
                </span>
              ) : null}
            </span>
          </div>
          <div className="textRight">
            : {localStorage.getItem("infoRefresh1KurdishAmount") || "بڕ"}
          </div>
        </div>
        {data?.type === "SupplierPayment" ||
        data?.type === "CustomerPayment" ||
        data?.type === "RepresentativePayment" ? (
          <div
            className={
              localStorage.getItem("show1forgive")
                ? "mb-4 d-flex justify-content-between"
                : "d-none"
            }
          >
            <div className="TextLeft">
              {localStorage.getItem("infoRefresh1EnglishForgive") ||
                "Forgive Price"}
              :
              <span
                style={{
                  fontWeight: `${
                    localStorage.getItem("bold1bodyBold") === "true" || "normal"
                  }`,
                }}
              >
                {data?.forgivePrice} &nbsp;
                {data?.dollar ? "USD" : "IQD"}
              </span>
            </div>
            <div className="textRight">
              : {localStorage.getItem("infoRefresh1KurdishForgive") || "خصم"}
            </div>
          </div>
        ) : null}

        <div className="mb-4 d-flex justify-content-between">
          <div className="TextLeft">
            {localStorage.getItem("infoRefresh1EnglishNote") || "Note"}: &nbsp;
            <span
              style={{
                fontWeight: `${
                  localStorage.getItem("bold1bodyBold") === "true"
                    ? "bold"
                    : "normal"
                }`,
              }}
            >
              {data?.note || "-"}
            </span>
          </div>
          <div className="textRight">
            : {localStorage.getItem("infoRefresh1KurdishNote") || "تێبینی"}
          </div>
        </div>
        <div className="mt-5  d-flex justify-content-around">
          <div className="TextLeft">
            {localStorage.getItem("infoRefresh1Sign") || "واژوو"}
          </div>
          <div className=" textRight">
            {localStorage.getItem("infoRefresh1Accountant") || "ژمێریار"}
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <main className="d-flex flex-column gap-5" dir="rtl">
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <h4
              style={textColor}
              //  className="text-color"
            >
              {data?.type === "SupplierPayment"
                ? "پسووڵەی پارەدانی دابینکەر"
                : data?.type === "CustomerPayment"
                ? "پسووڵەی وەرگرتنی کڕیار"
                : data?.type === "EmployeeLoan"
                ? "پسووڵەی پێدانی قەرزی کارمەند"
                : data?.type === "EmployeeReturnLoan"
                ? "پسووڵەی گەڕاندنەوەی قەرزی کارمەند"
                : data?.type === "Expense"
                ? "پسووڵەی خەرجی"
                : data?.type === "Withdraw"
                ? "پسووڵەی پارە دەرەهێنان"
                : data?.type === "Deposit"
                ? "پسووڵەی پارە دانان"
                : ""}
            </h4>
            <div className="text-center">
              <img
                className="logo"
                style={{ borderRadius: logo?.Radius || "1rem" }}
                src={logo?.Url || "https://i.ibb.co/k0X12vp/logo.jpg"}
                alt=""
              />
            </div>
          </div>

          <hr style={hrVoucher} />
          <div className="my-4">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex  align-items-center" style={textColor}>
                  <h6 className="">ژمارەی پسووڵە : &nbsp;</h6>
                  <h6 style={bgColor} className="text-light p-2 rounded">
                    {data?.invoiceNo && data?.invoiceNo.toUpperCase()}
                  </h6>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex  align-items-center" style={textColor}>
                  <h6 className="">بەروار : &nbsp;</h6>
                  <h6
                    dir="ltr"
                    style={bgColor}
                    className="text-light p-2 rounded"
                  >
                    {moment(data?.date).format("YYYY-MM-DD, hh:mm:ssA") ||
                      "24.08.2021"}
                  </h6>
                </div>
              </div>
            </div>

            {data?.type === "SupplierPayment" ||
            data?.type === "CustomerPayment" ? (
              <div class="">
                <div className="d-flex align-items-center color justify-content-center">
                  <h4 style={textColor}>
                    {data?.isDeposit ? "وەرگیرا لە :" : "درا بە :"}
                    &nbsp;
                  </h4>
                  <h4 className=" my-4">{data?.payedTo}</h4>
                </div>
                <div className="d-flex align-items-center color justify-content-between px-5 my-4">
                  <div className="d-flex align-items-center color justify-content-center">
                    <h5 style={textColor}>پارەی دراو:&nbsp;</h5>
                    <h5 dir="ltr" className="">
                      {data?.amount?.toLocaleString()}
                      {data?.dollar ? " $" : " IQD"}
                    </h5>
                  </div>
                  <div className="d-flex align-items-center color justify-content-center">
                    <h5 style={textColor}>داشکاندن:&nbsp;</h5>
                    <h5 dir="ltr" className="">
                      {data?.forgivePrice?.toLocaleString()}{" "}
                      {data?.dollar ? " $" : " IQD"}
                    </h5>
                  </div>
                </div>
              </div>
            ) : (
              <div class="">
                <div className="d-flex align-items-center color justify-content-between px-5 my-4">
                  <div className="d-flex align-items-center color justify-content-center">
                    <h4 style={textColor}>
                      {data?.isDeposit ? "وەرگیرا لە :" : "درا بە :"}
                      &nbsp;
                    </h4>
                    <h4 className=" my-4">{data?.payedTo}</h4>
                  </div>
                  <div className="d-flex align-items-center color justify-content-center">
                    <h4 style={textColor}>پارەی دراو:&nbsp;</h4>
                    <h4 dir="ltr" className="">
                      {data?.amount?.toLocaleString()}
                      {data?.dollar ? " $" : " IQD"}
                    </h4>
                  </div>
                </div>
              </div>
            )}

            {data?.type !== "Expense" &&
              data?.type !== "Withdraw" &&
              data?.type !== "Deposit" && (
                <div className="d-flex align-items-center color justify-content-between px-5 my-4">
                  <div className="d-flex align-items-center color justify-content-center">
                    <h5 style={textColor}>قەرزی کۆن:&nbsp;</h5>
                    <h5 dir="ltr" className="">
                      {oldLoan?.toLocaleString()}
                      {data?.dollar ? " $" : " IQD"}
                    </h5>
                  </div>
                  <div className="d-flex align-items-center color justify-content-center">
                    <h5 style={textColor}>قەرزی ئێستا:&nbsp;</h5>
                    <h5 dir="ltr" className="">
                      {data?.currentLoan?.toLocaleString()}{" "}
                      {data?.dollar ? " $" : " IQD"}
                    </h5>
                  </div>
                </div>
              )}
          </div>
          <hr style={hrVoucher} />

          <div className="d-flex align-items-center justify-content-around">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h6
                  //  className="text-color"
                  style={textColor}
                >
                  واژووی وەرگر: &nbsp;
                </h6>
                <h6 className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <h6
                    // className="text-color"
                    style={textColor}
                  >
                    واژووی پێدەر: &nbsp;
                  </h6>
                  <h6 className="">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-3  ">
            <h6 className="col-1 text-nowrap" style={textColor}>
              تێبینی :-
            </h6>
            <h6 className="col-11  note-voucher">{data?.note} &nbsp;</h6>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default HalfPage;
