import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import { path } from "../../request";
import { useParams } from "react-router-dom";
const GetProductPrices = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { id } = useParams();

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    await HTTP.get(`Products/GetPriceChanges/${id}?_start=${start}&_end=${end}`)
      .then((response) => {
        setData(response.data);
        setToTalItems(response.headers["x-total-count"]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      if (id) {
        getData();
      }
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div className="w-100">
      <div>
        <div name={"prices"}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.prices")}
            </h1>
          </div>
          <div className="hide-print">
            <Row className={`mt-2`}>
              {isLoading && <LoadingScreen />}

              <Col className="col-6  mt-4">
                <Button
                  id="export"
                  variant={"primary w-100"}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-6  mt-4">
                <Button
                  variant={"outline-primary w-100"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
            </Row>
          </div>
          <Row className="m-2 mt-4">
            <Col>
              <Table
                id={`exportableTable`}
                style={{ textAlign: "start" }}
                bordered
                hover
              >
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.prevPurchasePrice")}</th>

                    <th>{translate("resources.root.newPurchasePrice")}</th>
                    <th>
                      {translate("resources.root.prevSellPrice")} {" - "}
                      {translate("resources.root.smallMeasure")}
                    </th>
                    <th>
                      {translate("resources.root.newSellPrice")} {" - "}
                      {translate("resources.root.smallMeasure")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr key={one.id}>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.id}
                            </a>
                          </td>
                          <td>
                            {one.oldPurchaseByMain
                              ? `$${one.mcPreviousPurchasePrice?.toLocaleString()}`
                              : `${one.scPreviousPurchasePrice?.toLocaleString()} IQD`}
                          </td>

                          <td>
                            {one.newPurchaseByMain
                              ? `$${one.mcNewPurchasePrice?.toLocaleString()}`
                              : `${one.scNewPurchasePrice?.toLocaleString()} IQD`}
                          </td>
                          <td dir="ltr" style={{ textAlign: "end" }}>
                            {one.oldSellByMain
                              ? `$${one.mcPreviousSellPrice?.toLocaleString()} - $${one.mcPreviousSmallMeasureSellPrice?.toLocaleString()}`
                              : `${one.scPreviousSellPrice?.toLocaleString()} IQD - ${one.scPreviousSmallMeasureSellPrice?.toLocaleString()} IQD`}
                          </td>
                          <td dir="ltr" style={{ textAlign: "end" }}>
                            {one.newSellByMain
                              ? `$${one.mcNewSellPrice?.toLocaleString()} - $${one.mcNewSmallMeasureSellPrice?.toLocaleString()}`
                              : `${one.scNewSellPrice?.toLocaleString()} IQD - ${one.scNewSmallMeasureSellPrice?.toLocaleString()} IQD`}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Col>
          </Row>

          <div className="hide-print">
            <div style={{ position: "relative" }}>
              <InputGroup style={{ width: "200px", float: "right" }}>
                <InputGroup.Text>Per Page</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setPerPage(e.target.value)}
                  as="select"
                  custom
                >
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>{" "}
                  <option value={1000}>1000</option>
                  <option value={2000}>2000</option>
                  <option value={10000}>10000</option>
                </Form.Control>
              </InputGroup>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={perPage}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText={translate("resources.root.previous")}
                nextPageText={translate("resources.root.next")}
                itemClass="page-item"
                linkClass="page-link"
              />
              <div className={`clearfix`}> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetProductPrices;
