import * as React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { CardContent, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { url } from "./request";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import moment from "moment";
// import Select from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import FormControl from "@mui/material/FormControl";

// import { IoCalculator } from "react-icons/io5";
import { BsPersonSquare } from "react-icons/bs";
// import { CgDollar } from "react-icons/cg";
import { FaBox } from "react-icons/fa";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import SwipeableViews from "react-swipeable-views";
import Carousel from "react-material-ui-carousel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Tooltip } from "@mui/material";
import Fade from "@mui/material/Fade";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useRedirect, useTranslate } from "react-admin";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import AnimationButtons from "./components/CustomComponents/AnimationButtons";
import _ from "lodash";
import { hasPermission } from "./App";
import { permissions } from "./components/Permissions/AllPermission";
ChartJS.register(...registerables);
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const Dashboard = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const role = localStorage.getItem("role") ?? "NoUser";
  const pluck = (arr, key) => arr?.map((o) => o[key]);
  const [boxes, setBoxes] = React.useState([]);
  const [loggedInUsers, setLoggedInUsers] = React.useState([]);
  const [commands, setCommands] = React.useState([]);
  const [stores, setStores] = React.useState([]);

  const [sellChartData, setSellChartData] = React.useState([]);
  const [cashierSellChartData, setCashierSellChartData] = React.useState([]);
  const [purchaseChartData, setPurchaseChartData] = React.useState([]);
  const [representativesInvoiceChartData, setRepresentativesInvoiceChartData] =
    React.useState([]);
  const [
    representativesCustomerInvoiceChartData,
    setRepresentativesCustomerInvoiceChartData,
  ] = React.useState([]);
  const [expenseChartData, setExpenseChartData] = React.useState([]);
  const [paymentChartData, setPaymentChartData] = React.useState([]);
  const [visitsChart, setVisitsChart] = React.useState([]);
  const [detailsInfo, setDetailsInfo] = React.useState({
    count: 0,
    count2: 0,
    mcTotal: 0,
    mcTotal2: 0,
    scTotal: 0,
    scTotal2: 0,
  });

  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [activeTab, setActiveTab] = React.useState("sellInvoice");
  const [cashierAccounts, setCashierAccounts] = React.useState([]);

  const [showByMain, setShowByMain] = React.useState(false);
  const [showReturn, setShowReturn] = React.useState(false);
  const [hasExpires, setHasExpires] = React.useState(false);
  const [stockAlert, setStockAlert] = React.useState(false);

  const sellChart = {
    labels: pluck(
      sellChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          sellChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const purchaseChart = {
    labels: pluck(
      purchaseChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.purchases"),
        data: pluck(
          purchaseChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const representativesInvoiceChart = {
    labels: pluck(
      representativesInvoiceChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          representativesInvoiceChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const representativesInvoiceCustomerChart = {
    labels: pluck(
      representativesCustomerInvoiceChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          representativesCustomerInvoiceChartData.filter(
            (x) => x.lost === false
          ),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const expenseChart = {
    labels: pluck(
      expenseChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.expenses"),
        data: pluck(
          expenseChartData.filter((x) => x.lost === true),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const paymentChart = {
    labels: pluck(
      paymentChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.customers"),
        data: pluck(
          paymentChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const cashierSellsChart = {
    labels: pluck(
      cashierSellChartData?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.sells"),
        data: pluck(
          cashierSellChartData.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
  const visitChart = {
    labels: pluck(
      visitsChart?.map(({ date }) => {
        return { date: moment(date).format("YYYY-MM-DD") };
      }),
      "date"
    ),
    datasets: [
      {
        label: translate("resources.root.visits"),
        data: pluck(
          visitsChart.filter((x) => x.lost === false),
          showByMain ? "mcPrice" : "scPrice"
        ),
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options = { responsive: true };

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        setActiveTab("sellInvoice");
        getCharts("sellInvoice");
        break;
      case 1:
        setActiveTab("purchase");
        getCharts("purchase");
        break;
      case 2:
        setActiveTab("rep");
        getCharts("rep");
        break;
      case 3:
        setActiveTab("repcus");
        getCharts("repcus");
        break;
      case 4:
        setActiveTab("expense");
        getCharts("expense");
        break;
      case 5:
        setActiveTab("payment");
        getCharts("payment");
        break;
      case 6:
        setActiveTab("casher");
        getCharts("casher");
        break;
      case 7:
        setActiveTab("visits");
        getCharts("visits");
        break;
      default:
        setActiveTab("sellInvoice");
        getCharts("sellInvoice");
        break;
    }
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };

  function getCharts(type = "sellInvoice") {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = {
      Accept: "application/json",
      storeid: localStorage.getItem("storeId") ?? 1,
      ratio: localStorage.getItem("dollarPrice"),
      Authorization: `Bearer ${token}`,
      language: localStorage.getItem("locale") === "kr" ? "Kurdish" : "English",
      platform: "Web",
    };
    axios
      .get(
        `${url}/Dashboard/Charts?invoiceType=${type}&startDate=${startDate}&endDate=${endDate}&ret=${showReturn}`
      )
      .then((response) => {
        let responseData = _.orderBy(response.data, "date", "asc");
        switch (type) {
          case "sellInvoice":
            setSellChartData(responseData);
            break;
          case "casher":
            setCashierSellChartData(responseData);
            break;
          case "purchase":
            setPurchaseChartData(responseData);
            break;
          case "rep":
            setRepresentativesInvoiceChartData(responseData);
            break;
          case "repcus":
            setRepresentativesCustomerInvoiceChartData(responseData);
            break;
          case "expense":
            setExpenseChartData(responseData);
            break;
          case "payment":
            setPaymentChartData(responseData);
            break;
          case "visits":
            setVisitsChart(responseData);
            break;
          default:
            setSellChartData(responseData);
            break;
        }
        setDetailsInfo({
          count: parseFloat(response.headers["count"]),
          count2: parseFloat(response.headers["count2"]),
          mcTotal: parseFloat(response.headers["mctotal"]),
          mcTotal2: parseFloat(response.headers["mctotal2"]),
          scTotal: parseFloat(response.headers["sctotal"]),
          scTotal2: parseFloat(response.headers["sctotal2"]),
        });
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  }

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common = {
      Accept: "application/json",
      storeid: localStorage.getItem("storeId") ?? 1,
      ratio: localStorage.getItem("dollarPrice"),
      Authorization: `Bearer ${token}`,
      language: localStorage.getItem("locale") === "kr" ? "Kurdish" : "English",
      platform: "Web",
    };
    {
      (process.env.REACT_APP_APP_NAME === "Bahez" ||
        process.env.REACT_APP_APP_NAME === "Safeen") &&
      role.toLowerCase() === "accountant"
        ? axios
            .get(`${url}/BankAccounts?getAll=${true}`)
            .then((response) => {
              setBoxes(response.data);
            })
            .catch((error) => {
              console.log(error.response.status);
            })
        : axios
            .get(`${url}/BankAccounts`)
            .then((response) => {
              setBoxes(response.data);
            })
            .catch((error) => {
              console.log(error.response.status);
            });
    }

    axios
      .get(`${url}/Dashboard/LastLogins`)
      .then((response) => {
        setLoggedInUsers(response.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    let oldInLocal = JSON.parse(localStorage.getItem("quickCommands") || "[]");
    let elements = Object.values(oldInLocal) || [];
    setCommands(elements);
    getCharts("sellInvoice");

    axios
      .get(
        `${url}/Reports/ExpireReport?days=${
          localStorage.getItem("expireDaysDashboard") ?? 30
        }`
      )
      .then((response) => {
        setHasExpires(response.data.length > 0);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(
        `${url}/Reports/StockAlertReport?ignoreZero=${
          localStorage.getItem("ignoreZeroDashboard") ?? false
        }&ignoreBellowZeroProducts=${
          localStorage.getItem("ignoreBelowZeroProductsDashboard") ?? false
        }`
      )
      .then((response) => {
        setStockAlert(response.data.length > 0);
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    axios
      .get(`${url}/UserStoreBankAccounts`)
      .then((response) => {
        setCashierAccounts(response.data);
      })
      .catch((error) => {
        console.log(error.response.status);
      });

    if (role.toLowerCase() === "admin" || role.toLowerCase() === "superadmin") {
      axios
        .get(`${url}/Stores?_end=1000`)
        .then((response) => {
          setStores(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  React.useEffect(() => {
    getCharts(activeTab);
  }, [showReturn, startDate, endDate]);

  const tapData = [
    {
      index: 0,
      dataChart: sellChart,
      types: [
        {
          title: "resources.root.sellInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.sellReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 1,
      dataChart: purchaseChart,
      types: [
        {
          title: "resources.root.purchaseInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.purchaseReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 2,
      dataChart: representativesInvoiceChart,

      types: [
        {
          title: "resources.root.representativeInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.representativeReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 3,
      dataChart: representativesInvoiceCustomerChart,
      types: [
        {
          title: "resources.root.representativeCustomerInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.representativeCustomerReturnInvoice",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 4,
      dataChart: expenseChart,
      types: [
        {
          title: "resources.root.expenses",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
      ],
    },
    {
      index: 5,
      dataChart: paymentChart,
      types: [
        {
          title: "resources.root.customerPayments",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
        {
          title: "resources.root.supplierPayments",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count2,
          mcTotal: detailsInfo.mcTotal2,
          scTotal: detailsInfo.scTotal2,
        },
      ],
    },
    {
      index: 6,
      dataChart: cashierSellsChart,
      types: [
        {
          title: "resources.root.cashierSells",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
      ],
    },
    {
      index: 7,
      dataChart: visitChart,
      types: [
        {
          title: "resources.root.visits",
          title_1: "resources.root.countInvoice",
          title_2: "resources.root.total",
          count: detailsInfo.count,
          mcTotal: detailsInfo.mcTotal,
          scTotal: detailsInfo.scTotal,
        },
      ],
    },
  ];
  const tabChange = [
    { title: "Selling", index: 0 },
    { title: "Purchases", index: 1 },
    {
      title: "Representatives",
      class: `${
        process.env.REACT_APP_REPRESENTATIVE_MODULE !== "true" && "d-none"
      }`,
      index: 2,
    },
    {
      title: "RepresentativeCustomers",
      class: `${
        process.env.REACT_APP_REPRESENTATIVE_MODULE !== "true" && "d-none"
      }`,
      index: 3,
    },
    { title: "Expenses", index: 4 },
    { title: "Payments", index: 5 },
    {
      title: "cashierSells",
      class: `${process.env.REACT_APP_CASHIER_MODULE !== "true" && "d-none"}`,
      index: 6,
    },
    {
      title: "Visits",
      class: `${process.env.REACT_APP_APP_NAME !== "Hana" && "d-none"}`,
      index: 7,
    },
  ];

  const [backGroundTypeList, setBackGroundTypeList] = React.useState("Selling");

  const Cashiers = (
    <div className="row row-cols-2 row-cols-lg-3 justify-content-center align-items-center mx-  2">
      {cashierAccounts?.map((one, index) => {
        return (
          <div key={index} className=" p-1 p-md-2 p-lg-3">
            <div className="bg-white border rounded shadowed text-center p-2">
              <div className="">
                <BsPersonSquare /> &nbsp;
                <p className="m-0 p-0">
                  {`${one.firstName} ${one.middleName}`}
                </p>
              </div>
              <div>
                {process.env.REACT_APP_SHOW_MC === "true" && (
                  <p className={"card-main-header text-white m-1 p-1 rounded"}>
                    {`$ ${
                      one.mcCurrentCash && one.mcCurrentCash?.toLocaleString()
                    }`}
                  </p>
                )}

                {process.env.REACT_APP_SHOW_SC === "true" && (
                  <p className={"card-main-header text-white m-1 p-1 rounded"}>
                    {`${
                      one.scCurrentCash && one.scCurrentCash?.toLocaleString()
                    } `}
                    {translate("resources.root.iqd")}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={`dashboard p-0`}>
      {/*   main them */}
      <Grid container justifyContent={"center"}>
        <Grid item className="col-12 col-lg-9">
          {/* Storees invoices*/}
          <Grid
            item
            className="pt-2 pt-md-5 col-12 text-center startalign-items-center"
          >
            <h2 cl>{translate("ra.navigation.Dashboard")}</h2>
            {(role.toLowerCase() === "admin" ||
              role.toLowerCase() === "superadmin") && (
              <Col className="pt-3 col-12 col-md-8 col-lg-6">
                <Form.Group className="row" controlId="formHorizontalStoreId">
                  <Form.Label className="ms-3">
                    {translate("resources.root.store")}
                  </Form.Label>
                  <Col className="col-10 col-md-8 col-lg-6">
                    <Form.Control
                      className=" rounded newMenu-dashboard"
                      as={"select"}
                      onChange={(e) => {
                        localStorage.setItem("storeId", e.target.value);
                        window.location.reload();
                      }}
                      value={localStorage.getItem("storeId") ?? 1}
                    >
                      {stores?.map((store, index) => {
                        return (
                          <option
                            key={index}
                            className="option-menu"
                            value={store.id}
                          >
                            {store.title}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            )}
          </Grid>
          {/* 🤷‍♂️ */}
          <Grid className={`text-center row my-3`}>
            {commands &&
              commands?.map((command, index) => {
                return (
                  <Col className={`mt-1 col-6 col-md-4 col-lg-3`} key={index}>
                    <Button
                      block
                      onClick={() => redirect("/" + command.route)}
                      variant={"outline-secondary"}
                      style={{
                        height: 75,
                        width: "100%",
                        backgroundColor: command.bgColor,
                        color: command.color,
                        borderColor: command.bgColor,
                      }}
                    >
                      {translate("resources.root." + command.label)}
                    </Button>
                  </Col>
                );
              })}
          </Grid>
          {/* Cards */}

          {hasPermission([permissions.dashboardCashers]) && (
            <>
              <div className="d-none d-md-block" item>
                {Cashiers}
              </div>

              <Carousel
                className="my-2 mb-3 h-max d-md-none"
                navButtonsProps={{
                  padding: "1px",
                }}
                animation="slide"
              >
                {cashierAccounts?.map((one, index) => {
                  return (
                    <div key={index} className="p-1 p-md-2 p-lg-3">
                      <div className="bg-white border rounded shadowed text-center p-2">
                        <div className="">
                          <BsPersonSquare /> &nbsp;
                          <p className="m-0 p-0">
                            {`${one.firstName} ${one.middleName}`}
                          </p>
                        </div>
                        <div>
                          {process.env.REACT_APP_SHOW_MC === "true" && (
                            <p
                              className={
                                "btn-dollar text-white m-1 p-1 rounded"
                              }
                            >
                              {`$ ${
                                one.mcCurrentCash &&
                                one.mcCurrentCash?.toLocaleString()
                              }`}
                            </p>
                          )}

                          {process.env.REACT_APP_SHOW_SC === "true" && (
                            <p className={"btn-iqd text-white m-1 p-1 rounded"}>
                              {`${
                                one.scCurrentCash &&
                                one.scCurrentCash?.toLocaleString()
                              } `}
                              {translate("resources.root.iqd")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </>
          )}

          {/* Boxes */}
          <div className="d-md-none">
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h5 style={{ padding: "8px" }}>
                {translate("resources.root.boxes")}
              </h5>
            </Grid>
            <Carousel
              navButtonsProps={{
                padding: "1px",
              }}
            >
              {boxes?.map((box, index) => {
                return (
                  <Card style={{ textAlign: "center" }} key={index}>
                    <CardContent className="p-0">
                      <div className="d-flex flex-column justify-content-center align-items-center newSafeBox">
                        <div className="d-flex justify-content-center align-items-center gap-5 p-1">
                          <p>{box.name}</p>
                          <FaBox />
                        </div>

                        <div
                          className="blured py-3 w-100"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center gap-2 px-3">
                            {process.env.REACT_APP_SHOW_MC === "true" && (
                              <p className="btn btn-sm btn-block btn-light p-1">
                                {box.mcCurrentBalance?.toLocaleString()}
                                {translate("resources.root.usd")}
                              </p>
                            )}

                            {process.env.REACT_APP_SHOW_SC === "true" && (
                              <p className="btn btn-sm btn-block btn-light p-1">
                                {box.scCurrentBalance?.toLocaleString()}
                                {translate("resources.root.iqd")}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
            </Carousel>
          </div>

          {/* switch && date */}
          {hasPermission([permissions.dashboardTabs]) && (
            <Grid
              container
              className="col-12 mt-3 mb-lg-0 p-0 row justify-content-center"
            >
              {/* Switch */}
              <Grid
                className="col-12 mb-4 mb-lg-0 text-center p-0 row justify-content-center"
                item
              >
                <Col className="col-6 p-0 ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => {
                          setShowByMain(e.target.checked);
                        }}
                        name="gilad"
                      />
                    }
                    label={translate("resources.root.showByMain")}
                  />
                </Col>
                <Col className="col-6 p-0">
                  <FormControlLabel
                    className="m-0 text-nowrap"
                    control={
                      <Switch
                        onChange={(e) => {
                          setShowReturn(e.target.checked);
                        }}
                        name="gilad"
                      />
                    }
                    label={translate("resources.root.showReturn")}
                  />
                </Col>
              </Grid>
              {/* Date */}
              <Grid item className="col-12">
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Grid>
            </Grid>
          )}
          {/*Bar-dashbord*/}

          {hasPermission([permissions.dashboardTabs]) && (
            <>
              <Box
                className="px-md-4 mt-3 mx-auto"
                sx={{
                  flexGrow: 1,
                  maxWidth: { xs: 350, sm: 700, lg: "100%" },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  onChange={handleChange}
                  value={value}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  textColor="white"
                  className="bar-dashbord rounded"
                >
                  {tabChange?.map((tabItem) => (
                    <Tab
                      onClick={() => {
                        setBackGroundTypeList(`${tabItem.title}`);
                      }}
                      className={`${tabItem?.class} ${
                        backGroundTypeList === `${tabItem.title}`
                          ? "selected"
                          : "hoverd"
                      }`}
                      label={translate(`ra.navigation.${tabItem.title}`)}
                      {...a11yProps(tabItem.index)}
                    />
                  ))}
                </Tabs>{" "}
                {/* <Tabs
                  onChange={handleChange}
                  value={value}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  textColor="white"
                  className="bar-dashbord rounded"
                >
                  {tabChange?.map((tabItem) =>
                    process.env.REACT_APP_CASHIER_MODULE === "true" ? (
                      process.env.REACT_APP_APP_NAME === "Hana" ? (
                        <Tab
                          onClick={() => {
                            setBackGroundTypeList(`${tabItem.title}`);
                          }}
                          className={` ${
                            backGroundTypeList === `${tabItem.title}`
                              ? "selected"
                              : "hoverd"
                          }`}
                          label={translate(`ra.navigation.${tabItem.title}`)}
                          {...a11yProps(tabItem.index)}
                        />
                      ) : tabItem.title === "Visits" ? null : (
                        <Tab
                          onClick={() => {
                            setBackGroundTypeList(`${tabItem.title}`);
                          }}
                          className={` ${
                            backGroundTypeList === `${tabItem.title}`
                              ? "selected"
                              : "hoverd"
                          }`}
                          label={translate(`ra.navigation.${tabItem.title}`)}
                          {...a11yProps(tabItem.index)}
                        />
                      )
                    ) : tabItem.title === "cashierSells" ? null : (
                      <Tab
                        onClick={() => {
                          setBackGroundTypeList(`${tabItem.title}`);
                        }}
                        className={` ${
                          backGroundTypeList === `${tabItem.title}`
                            ? "selected"
                            : "hoverd"
                        }`}
                        label={translate(`ra.navigation.${tabItem.title}`)}
                        {...a11yProps(tabItem.index)}
                      />
                    )
                  )}
                </Tabs>{" "} */}
              </Box>
              {/*  sell & return card*/}
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
                className="p-0"
              >
                {tapData?.map((tapD, index) => (
                  <TabPanel
                    value={value}
                    index={tapD.index}
                    dir={theme.direction}
                    key={index}
                    className="p-0 w-100"
                  >
                    <div className=" row justify-content-center align-items-center">
                      {tapD?.types?.map((typeItem, index) => (
                        <Grid
                          item
                          className="col-12  col-lg-6 mt-2 mt-lg-0"
                          key={index}
                        >
                          <Card style={{ textAlign: "center" }} className="p-0">
                            <CardContent className="p-0">
                              <Row className="newSellReturnBox-dashboard">
                                <Col xs={12} className="pt-2 h5 ">
                                  {translate(typeItem.title)}
                                </Col>
                                <Col
                                  className="blured py-3"
                                  style={{ fontSize: "0.9rem" }}
                                  xs={12}
                                >
                                  <div className="px-4 d-flex flex-column gap-2">
                                    <Col>
                                      <Typography className="text-nowrap h6 d-flex justify-content-around">
                                        {translate(typeItem.title_1)}
                                        :&nbsp;{typeItem.count}
                                      </Typography>
                                    </Col>
                                    <Col className="d-flex gap-5 justify-content-center align-content-center">
                                      {process.env.REACT_APP_SHOW_MC ===
                                        "true" && (
                                        <Typography className="text-nowrap h6">
                                          {translate(typeItem.title_2)}: ${" "}
                                          {typeItem.mcTotal?.toLocaleString()}
                                        </Typography>
                                      )}
                                      {process.env.REACT_APP_SHOW_SC ===
                                        "true" && (
                                        <Typography className="text-nowrap h6">
                                          {translate(typeItem.title_2)}:{" "}
                                          {typeItem.scTotal?.toLocaleString()}{" "}
                                          {translate("resources.root.iqd")}
                                        </Typography>
                                      )}
                                    </Col>
                                  </div>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                      <Grid id item xs={12} className="mt-3 w-100">
                        <Line
                          data={tapD.dataChart}
                          options={options}
                          type={`line`}
                        />
                      </Grid>
                    </div>
                  </TabPanel>
                ))}
              </SwipeableViews>
            </>
          )}
        </Grid>

        {/* Boxes & Alerts & Login */}
        <div
          className="boxes col-12 col-lg-3 m-md-0 mb-5 text-center p-md-1 pe-0 pb-md-0
                    d-flex flex-column gap-2 justify-content-start align-items-center"
        >
          {hasPermission([permissions.dashboardSafeboxes]) && (
            <div className="d-none d-md-block w-100">
              <h5 className="text-center">
                {translate("resources.root.boxes")}
              </h5>
              <Carousel
                navButtonsProps={{
                  padding: "1px",
                }}
              >
                {boxes?.map((box, index) => {
                  return (
                    <Card key={index}>
                      <CardContent className="p-0">
                        <div className="d-flex flex-column justify-content-center align-items-center newSafeBox">
                          <div className="d-flex justify-content-center align-items-center gap-2 p-2">
                            <h6 className="m-0">{box.name}</h6>
                            <FaBox />
                          </div>

                          <div className="blured py-3 w-100">
                            <div className="d-flex flex-column justify-content-center align-items-center gap-2 px-3">
                              {process.env.REACT_APP_SHOW_MC === "true" && (
                                <p className="btn btn-sm btn-block btn-light p-1">
                                  {box.mcCurrentBalance?.toLocaleString()}
                                  {translate("resources.root.usd")}
                                </p>
                              )}

                              {process.env.REACT_APP_SHOW_SC === "true" && (
                                <p className="btn btn-sm btn-block btn-light p-1">
                                  {box.scCurrentBalance?.toLocaleString()}
                                  {translate("resources.root.iqd")}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  );
                })}
              </Carousel>
            </div>
          )}

          {/* Expire */}
          {hasExpires && (
            <AnimationButtons
              text={translate("resources.root.expireReport")}
              link={"#/Reports/ExpireReport"}
            />
          )}
          {/* Stock Alert */}
          {stockAlert && (
            <AnimationButtons
              text={translate("resources.root.stockReport")}
              link={"#/Reports/StockReport"}
            />
          )}
          <div className="d-flex flex-column gap-2 w-100">
            <h5 className="m-0">{translate("resources.root.lastLogins")}</h5>
            <div className={`lastLoggedInUsers w-100`}>
              {loggedInUsers &&
                loggedInUsers?.map((loggedInUser, index) => {
                  return (
                    <Accordion
                      style={{ fontSize: "0.8rem" }}
                      className="last-login-dashboard"
                      key={index}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: "white" }} />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ color: "white" }}
                      >
                        <p className="">
                          {`${loggedInUser.firstName} ${loggedInUser.middleName} ${loggedInUser.lastName}`}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails className="p-1">
                        <p>
                          {translate("resources.root.time")}:
                          {moment(loggedInUser.lastLoginDate).format(
                            "YYYY-MM-DD hh:mm:ss A"
                          )}
                          <br />
                          {translate("resources.root.ip")}:
                          {loggedInUser.lastLoginIp}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Dashboard;
